import { Flex, Typography } from 'antd';

import { Schemas } from '@api-client/generated/types';
import { Loader } from '@components';
import { DEFAULT_CURRENCY_CODE } from '@constants';
import { Amount } from '@entities/index';

import * as S from './styled';

type BillingPeriod = Schemas.BillingPeriod;
const { Text } = Typography;

type BillingPeriodProps = {
  currentPeriod?: BillingPeriod;
  isLoading: boolean;
};

const BillingCurrentPeriod = ({
  currentPeriod,
  isLoading,
}: BillingPeriodProps) => {
  const title = t('settings.billing.paymentDetails.currentPeriod.title')();

  if (isLoading) {
    return (
      <S.Card title={title}>
        <Loader />
      </S.Card>
    );
  }

  if (!currentPeriod?.items) {
    return null;
  }

  const total = currentPeriod.items.reduce(
    (current, prev) => current + prev.totalPrice,
    0
  );

  return (
    <S.Card title={title}>
      <S.ListHeaderRow>
        <Text>
          {t(
            'settings.billing.paymentDetails.currentPeriod.table.description'
          )()}
        </Text>
        <Text>
          {t('settings.billing.paymentDetails.currentPeriod.table.price')()}
        </Text>
      </S.ListHeaderRow>
      {currentPeriod.items.map((item) => (
        <S.ListRow key={item.id} justify="space-between">
          <Flex vertical>
            <Text strong>{item.name}</Text>
            <Text>{item.description}</Text>
          </Flex>
          <Flex align="center">
            <Amount
              amount={item.totalPrice}
              currencyCode={DEFAULT_CURRENCY_CODE}
              withoutType
            />
          </Flex>
        </S.ListRow>
      ))}
      <S.Total gap={16} justify="flex-end">
        <Text>
          {t('settings.billing.paymentDetails.currentPeriod.table.total')()}
        </Text>
        <Text>
          <Amount
            as="span"
            amount={total}
            currencyCode={DEFAULT_CURRENCY_CODE}
            withoutType
          />
        </Text>
      </S.Total>
    </S.Card>
  );
};

export default BillingCurrentPeriod;
