import { Button, Flex, Table, type TableColumnType } from 'antd';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import { IconEye, IconEyeOff } from '@assets';
import { Scrollbar } from '@components';
import { TransactionsImportTableConfig } from '@entities';
import { useTransactionsImport, useTranslate } from '@hooks';

import * as config from './config';
import * as S from './styled';

type TransactionsImportTableProps = {
  result: Schemas.ValidationResult | undefined;
};

const TransactionsImportTable: FC<TransactionsImportTableProps> = ({
  result,
}) => {
  const { translate } = useTranslate();
  const { preview, mapping, setMapping } = useTransactionsImport();

  const [firstDataSource, setFirstDataSource] = useState<config.DataSource[]>(
    config.createDataSource({ rows: preview.firstRows, type: 'first', mapping })
  );

  const [lastDataSource, setLastDataSource] = useState<config.DataSource[]>(
    config.createDataSource({
      rows: preview.lastRows,
      type: 'last',
      mapping,
      lastRowsCount: preview.lastRows.length,
    })
  );

  const [firstColumns, setFirstColumns] = useState<
    TableColumnType<config.DataSource>[]
  >([]);
  const [lastColumns, setLastColumns] = useState<
    TableColumnType<config.DataSource>[]
  >([]);

  const columns = config.createColumns(preview.firstRows);

  const halfRows = Math.ceil(preview.firstRows.length / 2);

  useEffect(
    () => {
      if (preview.firstRows.length && !preview.lastRows.length) {
        setFirstDataSource(
          config.createDataSource({
            rows: _.slice(preview.firstRows, 0, halfRows),
            type: 'first',
            mapping,
          })
        );

        setLastDataSource(
          config.createDataSource({
            rows: _.slice(preview.firstRows, halfRows),
            type: 'last',
            mapping,
            lastRowsCount: _.slice(preview.firstRows, halfRows)?.length,
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preview]
  );

  const handleChangeVisible = (
    dataIndex: number,
    type: config.TypeDataSource
  ) => {
    const isFirst = type === 'first';
    const splicedFirstRows = config.createDataSource({
      rows: _.slice(preview.firstRows, 0, halfRows),
      type: 'first',
      mapping,
    });
    const splicedLastRows = config.createDataSource({
      rows: _.slice(preview.firstRows, halfRows),
      type: 'last',
      mapping,
      lastRowsCount: _.slice(preview.firstRows, halfRows)?.length,
    });
    const dataSourceByType = isFirst
      ? !preview.lastRows.length
        ? splicedFirstRows
        : firstDataSource
      : lastDataSource.length
        ? lastDataSource
        : !preview.lastRows.length
          ? splicedLastRows
          : firstDataSource;

    const dataSource = dataSourceByType.map((item, index) => {
      if (
        (dataIndex === 0 || dataIndex === dataSourceByType.length - 1) &&
        !dataSourceByType[dataIndex].isVisibleColumn
      ) {
        return {
          ...item,
          isVisibleColumn: true,
        };
      }

      if (isFirst) {
        if (dataIndex > +mapping.topRowsSkipAmount - 1) {
          const isVisibleColumn = !(dataIndex >= index);

          return {
            ...item,
            isVisibleColumn: isVisibleColumn,
          };
        } else if (dataIndex < +mapping.topRowsSkipAmount - 1) {
          const isVisibleColumn = !(dataIndex > index);

          return {
            ...item,
            isVisibleColumn: isVisibleColumn,
          };
        } else {
          return {
            ...item,
            isVisibleColumn: true,
          };
        }
      } else {
        if (
          dataIndex >
          dataSourceByType.length - mapping.bottomRowsSkipAmount
        ) {
          const isVisibleColumn = !(dataIndex < index);

          return {
            ...item,
            isVisibleColumn: isVisibleColumn,
          };
        } else if (
          dataIndex <
          dataSourceByType.length - mapping.bottomRowsSkipAmount
        ) {
          const isVisibleColumn = !(dataIndex <= index);

          return {
            ...item,
            isVisibleColumn: isVisibleColumn,
          };
        } else {
          return {
            ...item,
            isVisibleColumn: true,
          };
        }
      }
    });

    const countNotVisibleColumns = dataSource.filter(
      (item) => !item.isVisibleColumn
    ).length;

    if (isFirst) {
      setFirstDataSource(dataSource);

      setMapping({
        topRowsSkipAmount: countNotVisibleColumns,
      });
    } else {
      setLastDataSource(dataSource);

      setMapping({
        bottomRowsSkipAmount: countNotVisibleColumns,
      });
    }
  };

  const handleChangeMapping = (
    key: string,
    columnNumber: number,
    columnFormat: string = ''
  ) => {
    setMapping(
      {
        [key]: {
          isSkipped: false,
          columnNumber,
          columnFormat,
        },
      },
      false,
      key,
      columnNumber
    );
  };

  const columnsWithActions = (
    type: config.TypeDataSource
  ): TableColumnType<config.DataSource>[] => [
    {
      key: 'isVisibleColumn',
      render: (row, _, index) =>
        row.isVisibleColumn ? (
          <Flex>
            <Button
              type="text"
              size="small"
              onClick={() => handleChangeVisible(index, type)}
            >
              <IconEye />
            </Button>
          </Flex>
        ) : (
          <Flex>
            <S.ButtonEyeOff
              type="text"
              size="small"
              onClick={() => handleChangeVisible(index, type)}
            >
              <IconEyeOff />
            </S.ButtonEyeOff>
          </Flex>
        ),
    },
    ...columns.map((column, index) => ({
      key: index,
      title: (
        <TransactionsImportTableConfig
          columnData={config.getSelectedColumnNumber(mapping, index)}
          onChange={(key: string, format?: string | undefined) => {
            handleChangeMapping(key, index, format);
            if (type === 'first') {
              setFirstColumns((prev) =>
                prev.map((col) =>
                  col.key === index ? { ...col, className: '' } : col
                )
              );
            }
          }}
        />
      ),
      className:
        result &&
        result?.error?.field &&
        // @ts-expect-error-next-line
        mapping[result?.error?.field]?.columnNumber === index
          ? 'cell-error'
          : Object.keys(mapping).find(
                (el) =>
                  // @ts-expect-error-next-line
                  mapping[el]?.columnNumber === index &&
                  el !== result?.error?.field
              )
            ? ''
            : 'column_not_selected',
      ...column,
    })),
  ];

  useEffect(
    () => {
      setFirstColumns(columnsWithActions('first'));
      setLastColumns(columnsWithActions('last'));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapping]
  );

  return (
    <S.Container>
      <Scrollbar>
        <Table
          rowKey={({ tableRowKey }) => tableRowKey as string}
          rowClassName={(row) => (!row.isVisibleColumn ? 'row-disabled' : '')}
          dataSource={firstDataSource}
          columns={firstColumns}
          loading={false}
          pagination={false}
        />

        {preview.skippedRows > 0 && (
          <S.SkippedRows>
            <Trans
              i18nKey={translate('transactionsImport.skippedRows')}
              components={[
                <strong>
                  {preview.skippedRows}{' '}
                  {translate('transactionsImport.skippedRowsOperations')}
                </strong>,
              ]}
            />
          </S.SkippedRows>
        )}

        {lastDataSource?.length ? (
          <Table
            rowKey={({ tableRowKey }) => tableRowKey as string}
            rowClassName={(row) => (!row.isVisibleColumn ? 'row-disabled' : '')}
            dataSource={lastDataSource}
            columns={lastColumns}
            showHeader={false}
            loading={false}
            pagination={false}
          />
        ) : null}
      </Scrollbar>
    </S.Container>
  );
};

export default TransactionsImportTable;
