import { Col, DatePicker, Flex, Row, message } from 'antd';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { BankDefaultLogotype } from '@assets';
import {
  Amount,
  FilterCategories,
  FilterProjects,
  PaymentDocumentType,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useUpdateTransactionById } from '@hooks-api';

import * as S from './styled';

type PaymentDocumentTransactionProps = {
  type: PaymentDocumentType;
  logo: string;
  transaction: Schemas.Transaction;
  onRefreshFile: () => void;
  onDetach?: () => void;
  selected: boolean;
  onSelect?: () => void;
};

const PaymentDocumentTransaction: FC<PaymentDocumentTransactionProps> = ({
  logo,
  transaction,
  onRefreshFile,
  onDetach,
  selected,
  onSelect,
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [isErrorLogo, setIsErrorLogo] = useState(false);

  const { mutate: updateTransaction } = useUpdateTransactionById();

  const onFinish = (key: string, value: any) => {
    updateTransaction(
      {
        parameter: {
          id: transaction.id!,
          companyId: companyId!,
        },
        requestBody: {
          [key]: value,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('transaction.success.update'));
          onRefreshFile();
        },
      }
    );
  };

  const handleRedirectToTransaction = () =>
    navigate(`/transactions/${transaction.id}`);

  return (
    <S.Transaction
      isselected={selected ? 'true' : ''}
      onClick={onSelect}
      justify="space-between"
      vertical
    >
      <S.Header>
        <S.HeaderInner justify="space-between">
          <Flex vertical>
            <S.Title onClick={handleRedirectToTransaction} data-testid="title">
              {transaction.contact?.name}
            </S.Title>

            <S.Description data-testid="description">
              {transaction.details}
            </S.Description>
          </Flex>

          <S.Logo
            src={isErrorLogo ? BankDefaultLogotype : logo}
            alt={transaction.contact?.name}
            onError={() => setIsErrorLogo(true)}
            data-testid="logo"
          />
        </S.HeaderInner>

        <Flex align="center" justify="space-between">
          <S.Amount>
            <Amount
              data-testid="amount"
              amount={transaction.amount}
              currencyCode={transaction.currencyCode}
              withoutType
            />
          </S.Amount>

          <Flex gap={12} align="center">
            {onDetach && (
              <S.DetachButton type="text" onClick={onDetach}>
                Detach
              </S.DetachButton>
            )}
            <S.Date data-testid="date">
              {dayjs(transaction.bookingDate).format('DD.MM.YYYY')}
            </S.Date>
          </Flex>
        </Flex>
      </S.Header>

      <S.Content>
        <Row gutter={[8, 0]}>
          <Col span={12}>
            <FilterCategories
              listWidth={200}
              variant="outlined"
              placeholder={translate('transaction.form.category.placeholder')}
              value={
                transaction.category && {
                  label: transaction.category?.name,
                  value: transaction.category?.id,
                }
              }
              onChange={(value) => onFinish('category', { id: value })}
              withoutContainer
              withGroups
            />
          </Col>

          <Col span={12}>
            <DatePicker
              placeholder={translate('transaction.form.plDate.placeholder')}
              size="large"
              picker="month"
              format="MMM YYYY"
              value={transaction.plDate && dayjs(transaction?.plDate)}
              onChange={(date) => onFinish('plDate', date)}
            />
          </Col>
        </Row>

        <S.ProjectsContainer>
          <FilterProjects
            variant="outlined"
            placeholder={translate('common.actions.selectOrCreateProject')}
            value={
              transaction.project && {
                label: transaction.project?.name,
                value: transaction.project?.id,
              }
            }
            onChange={(value) => onFinish('project', { id: value })}
            withoutContainer
          />
        </S.ProjectsContainer>
      </S.Content>
    </S.Transaction>
  );
};

export default PaymentDocumentTransaction;
