import { DefaultOptionType } from 'antd/es/select';
import { FC, useEffect, useState } from 'react';

import { Select, type SelectProps } from '@components';
import { useAccount, useClickOutside, useTranslate } from '@hooks';
import { useCreateProject, useGetProjectsList } from '@hooks-api';
import { selectFilterOption } from '@utils';

import * as S from './styled';

const FilterProjects: FC<SelectProps> = ({
  placeholder,
  onChange,
  ...rest
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const {
    data: projects,
    loading: projectsLoading,
    refetch,
  } = useGetProjectsList({
    params: {
      companyId: companyId!,
    },
  });

  const [projectsList, setProjectsList] = useState(projects);
  const [newProjectName, setNewProjectName] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { mutate: createProject } = useCreateProject();

  const ref = useClickOutside(() => setNewProjectName(''));

  useEffect(() => {
    if (!projectsLoading) {
      setProjectsList(projects);
    }
  }, [projects, projectsLoading]);

  const filterSort = (
    firstOption: DefaultOptionType,
    secondOption: DefaultOptionType
  ) => {
    if (
      typeof firstOption?.label === 'string' &&
      typeof secondOption?.label === 'string'
    ) {
      return (firstOption?.label ?? '')
        ?.toLowerCase()
        .localeCompare((secondOption?.label ?? '').toLowerCase());
    }
    return 0;
  };

  const handleCreateProject = () => {
    createProject(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          name: newProjectName,
        },
      },
      {
        onSuccess: (res) => {
          setSearchValue('');
          setProjectsList((prev) => (prev ? [...prev, res] : [res]));
          onChange?.(res.id, { label: res.name, value: res.id });
          refetch();
          setIsDropdownOpen(false);
          setNewProjectName('');
        },
      }
    );
  };

  const handleChange = (value: any, option: any) => {
    onChange?.(value, option);
    setSearchValue('');
    setNewProjectName('');
    setIsDropdownOpen(false);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    isDropdownOpen && setNewProjectName(value);
  };

  const createProjectButtonVisibilityCondition =
    newProjectName &&
    !projectsList?.find((project) =>
      project.name.toLowerCase().includes(newProjectName.toLowerCase())
    );

  useEffect(() => {
    const dropdownEmptyMenu = document.querySelector(
      '.ui-select-dropdown-empty'
    );

    if (dropdownEmptyMenu) {
      dropdownEmptyMenu.className = 'select-without-menu';
    }
  }, [searchValue]);

  return (
    <S.SelectContainer ref={ref}>
      <Select
        showSearch
        placeholder={placeholder || translate('common.navigation.projects')}
        options={projectsList?.map((project) => ({
          label: project.name,
          value: project.id,
        }))}
        filterOption={selectFilterOption}
        filterSort={filterSort}
        searchValue={searchValue}
        onSearch={handleSearch}
        onChange={handleChange}
        size="large"
        open={isDropdownOpen}
        onDropdownVisibleChange={setIsDropdownOpen}
        popupClassName={'select-popup-none'}
        {...rest}
      />
      {createProjectButtonVisibilityCondition && (
        <S.AddProjectButton
          onClick={handleCreateProject}
          type="primary"
          size="small"
        >
          {translate('common.actions.saveAsNew')}
        </S.AddProjectButton>
      )}
    </S.SelectContainer>
  );
};

export default FilterProjects;
