import { Button, Checkbox, Flex, Form, Input, Typography } from 'antd';
import { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { OnboardingCardHeader } from '@entities';
import { useAccount, useFormValidateFields, useOnboarding } from '@hooks';
import { useCreateIncorporation } from '@hooks-api';
import useTranslate from '@hooks/useTranslate';

import * as S from './styled';

type Values =
  | Schemas.LoginDto
  | Omit<Schemas.RegisterDto & { agree: boolean }, 'locale'>;

type OnboardingCreateAccountProps = {
  defaultFormName?: 'signUp' | 'login';
  onSubmit?: (values: Values) => void;
};

const { Paragraph } = Typography;

const OnboardingCreateAccount: FC<OnboardingCreateAccountProps> = ({
  defaultFormName = 'signUp',
  onSubmit,
}) => {
  const { tDynamic } = useTranslate();
  const { updateToken, setAccount, setCompany } = useAccount();

  const { incorporationDetails, updateScreen, updateIncorporation } =
    useOnboarding();

  const { form, disabledForm } = useFormValidateFields();

  const [selectedFormName, setSelectedFormName] = useState<'signUp' | 'login'>(
    defaultFormName
  );

  const [passwordError, setPasswordError] = useState<string | null>();

  const [emailError, setEmailError] = useState<string | null>();

  const { mutate: createIncorporation, isLoading } = useCreateIncorporation();

  const handleBack = () => {
    updateScreen('DESCRIBE', { active: true });
    setSelectedFormName('signUp');
  };

  const handleChangeFormName = () => {
    setSelectedFormName((prevState) =>
      prevState === 'login' ? 'signUp' : 'login'
    );
  };

  const handleSubmit = (values: Values) => {
    onSubmit?.(values);

    if (incorporationDetails) {
      const { companyNames, companyActivity, incorporationType } =
        incorporationDetails;
      const isSignUp = selectedFormName === 'signUp';

      const formValues = isSignUp
        ? {
            ...incorporationDetails.signUp,
            ...values,
          }
        : {
            ...incorporationDetails.login,
            ...values,
          };

      createIncorporation(
        {
          requestBody: {
            [selectedFormName]: formValues,
            companyNames,
            companyActivity,
            incorporationType,
          },
        },
        {
          onSuccess: (response) => {
            updateToken(response.jwtData?.accessToken as string);
            setAccount((response.jwtData as Schemas.UserJwtResponse).user!);
            setCompany({ id: response.id } as Schemas.Company);

            updateIncorporation(response);

            updateScreen(
              isSignUp ? 'VERIFY_ACCOUNT' : 'MESSAGE_COMPLETED_COMPANY',
              { active: true }
            );
          },
          onError: (error) => {
            for (const message of error.response?.data?.message || []) {
              if (message.startsWith('email.')) {
                setEmailError(message);
              }

              if (message.startsWith('password.')) {
                setPasswordError(message);
              }
            }
          },
        }
      );
    }
  };

  return (
    <S.Container>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <S.Inner>
          <OnboardingCardHeader
            title={t('onboarding.createAccount.title')()}
            description={
              <Paragraph>
                {t('onboarding.createAccount.description')()}
              </Paragraph>
            }
          />

          <S.Wrapper gap={50}>
            <S.Form>
              {selectedFormName === 'signUp' && (
                <S.FormInner>
                  <Form.Item
                    label={t('onboarding.createAccount.name.label')()}
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: t('onboarding.createAccount.name.error')(),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'onboarding.createAccount.name.placeholder'
                      )()}
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('onboarding.createAccount.phoneNumber.label')()}
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'onboarding.createAccount.phoneNumber.placeholder'
                        )(),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'onboarding.createAccount.phoneNumber.placeholder'
                      )()}
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('onboarding.createAccount.email.label')()}
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: t(
                          'onboarding.createAccount.email.placeholder'
                        )(),
                      },
                    ]}
                    validateStatus={emailError ? 'error' : ''}
                    help={
                      emailError &&
                      tDynamic(`onboarding.createAccount.${emailError}`)
                    }
                  >
                    <Input
                      placeholder={t(
                        'onboarding.createAccount.email.placeholder'
                      )()}
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('onboarding.createAccount.password.label')()}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t('onboarding.createAccount.password.error')(),
                      },
                    ]}
                    validateStatus={passwordError ? 'error' : ''}
                    help={
                      passwordError &&
                      tDynamic(`common.errors.${passwordError}`)
                    }
                  >
                    <Input.Password
                      placeholder={t(
                        'onboarding.createAccount.password.placeholder'
                      )()}
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    name="agree"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: t('onboarding.createAccount.pleaseAgree')(),
                      },
                    ]}
                  >
                    <Checkbox data-testid="checkbox-control-agree">
                      <Trans
                        i18nKey={t('onboarding.createAccount.agree')()}
                        components={[
                          <Link
                            to="https://easybiz.lu/privacy-policy"
                            target="_blank"
                          />,
                          <Link
                            to="https://easybiz.lu/terms"
                            target="_blank"
                          />,
                        ]}
                      />
                    </Checkbox>
                  </Form.Item>
                </S.FormInner>
              )}

              {selectedFormName === 'login' && (
                <S.FormInner>
                  <Form.Item
                    label={t('onboarding.createAccount.email.label')()}
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: t(
                          'onboarding.createAccount.email.placeholder'
                        )(),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        'onboarding.createAccount.email.placeholder'
                      )()}
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('onboarding.createAccount.password.label')()}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t('onboarding.createAccount.password.label')(),
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={t(
                        'onboarding.createAccount.password.placeholder'
                      )()}
                      size="large"
                    />
                  </Form.Item>
                </S.FormInner>
              )}
            </S.Form>

            <S.Actions>
              <S.Info strong>
                {selectedFormName === 'signUp' && (
                  <Trans
                    i18nKey={t('onboarding.createAccount.signIn')()}
                    components={[<a onClick={handleChangeFormName} />]}
                  />
                )}

                {selectedFormName === 'login' && (
                  <Trans
                    i18nKey={t('onboarding.createAccount.signUp')()}
                    components={[<a onClick={handleChangeFormName} />]}
                  />
                )}
              </S.Info>
            </S.Actions>
          </S.Wrapper>
        </S.Inner>

        <Flex justify="flex-end">
          <Button type="text" onClick={handleBack}>
            {t('onboarding.buttonGoBack')()}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={disabledForm}
          >
            {selectedFormName === 'signUp'
              ? t('onboarding.buttonSignUp')()
              : t('onboarding.buttonSignIn')()}
          </Button>
        </Flex>
      </Form>
    </S.Container>
  );
};

export default OnboardingCreateAccount;
