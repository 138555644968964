import { Button } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconUpload } from '@assets';
import { PaymentDocumentImportInvoice, PaymentDocumentType } from '@entities';
import { colors } from '@theme';

import * as S from './styled';

type PaymentDocumentDropFile = {
  type: PaymentDocumentType;
  onUpdate?: (file: Schemas.Document) => void;
};

const PaymentDocumentDropFile: FC<PaymentDocumentDropFile> = ({
  type,
  onUpdate,
}) => {
  const translationPrefix = type === 'income' ? 'income' : 'expenses';

  return (
    <PaymentDocumentImportInvoice documentType={type} onUpdate={onUpdate}>
      <S.Container>
        <IconUpload color={colors.textBlue400} />

        <S.Title>{t(`${translationPrefix}.dropFile.title`)()}</S.Title>

        <S.Description>
          {t(`${translationPrefix}.dropFile.description`)()}
        </S.Description>

        <Button size="small">
          {t(`${translationPrefix}.dropFile.buttonSelect`)()}
        </Button>
      </S.Container>
    </PaymentDocumentImportInvoice>
  );
};

export default PaymentDocumentDropFile;
