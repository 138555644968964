import { Button, Flex, Grid, Typography } from 'antd';
import { FC, Fragment, ReactNode } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconBag, IconCheck, IconClock, IconWarningTransparent } from '@assets';
import { ScreenName } from '@context';
import { OnboardingCardHeader, StatusTag } from '@entities';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';
import { LocalePaths } from '@locales';

import * as S from './styled';

export type Status =
  | 'inProgress'
  | 'completed'
  | 'errorsFound'
  | 'onReview'
  | null;

type StepEntity = {
  key: string;
  title: string | null;
  content?: ReactNode;
  badge?: string | null;
  status: Status | null;
  active?: boolean;
  isDivider?: boolean;
};

const { useBreakpoint } = Grid;
const { Paragraph } = Typography;

const OnboardingOverview: FC = () => {
  const { translate } = useTranslate();
  const { companyId, userAccess } = useAccount();
  const breakpoint = useBreakpoint();

  const {
    steps: {
      COMPANY_NAME,
      SHAREHOLDERS,
      ONLINE_KYC,
      VERIFY_DOCUMENTS,
      BANK_TRANSFERRING,
      REGISTRATION_IN_RCS,
      INCORPORATED,
    },
    screens,
    updateScreen,
    allPeopleHaveEmails,
    incorporationDetails,
    isIncorporation,
  } = useOnboarding();

  const { mutate: updateIncorporation, isLoading: loading } =
    useUpdateIncorporation();

  const getStepContentByStatus = (
    status: Status,
    defaultBadge?: LocalePaths
  ) => {
    if (status === 'completed') {
      return translate('common.statuses.completed');
    }

    if (status === 'inProgress') {
      return translate('common.statuses.inProgress');
    }

    if (status === 'onReview') {
      return translate('common.statuses.onReview');
    }

    if (status === 'errorsFound') {
      return translate('common.statuses.errorsFound');
    }

    return defaultBadge ? translate(defaultBadge) : null;
  };

  const handleUpdateStep = (screen: ScreenName, group: string) => {
    updateScreen(screen, { active: true });

    updateIncorporation({
      parameter: {
        companyId: companyId!,
      },
      requestBody: {
        group,
      },
    });
  };

  const maybeGoToTransactions = () => {
    if (INCORPORATED.active) {
      location.replace(
        userAccess?.transactions ? '/transactions' : '/documents'
      );
    }
  };

  const stepsListIncorporation: StepEntity[] = [
    {
      key: 'companyName',
      title: translate('onboarding.incorporation.stepOne.title'),
      content: (
        <Flex gap={20} align="flex-start" vertical>
          {translate('onboarding.incorporation.stepOne.description')}

          {COMPANY_NAME.status !== 'completed' && COMPANY_NAME.active ? (
            <Button
              type="primary"
              size="small"
              onClick={() => updateScreen('COMPANY_NAME', { active: true })}
              loading={loading}
            >
              {translate('onboarding.buttonStart')}
            </Button>
          ) : null}
        </Flex>
      ),
      badge: getStepContentByStatus(
        COMPANY_NAME.status,
        'onboarding.incorporation.stepOne.extra'
      ),
      status: COMPANY_NAME.status,
      active: COMPANY_NAME.active,
    },
    !breakpoint.sm
      ? {
          key: 'divider',
          title: null,
          status: null,
          isDivider: true,
        }
      : ({} as StepEntity),
    {
      key: 'shareholders',
      title: translate('onboarding.incorporation.stepTwo.title'),
      content: (
        <Flex gap={20} align="flex-start" vertical>
          {translate('onboarding.incorporation.stepTwo.description')}

          {SHAREHOLDERS.status !== 'completed' && SHAREHOLDERS.active ? (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                handleUpdateStep('COMPANY_TYPE', 'shareholders');
              }}
              loading={loading}
            >
              {translate('onboarding.buttonStart')}
            </Button>
          ) : null}
        </Flex>
      ),
      badge: getStepContentByStatus(
        SHAREHOLDERS.status,
        'onboarding.incorporation.stepTwo.extra'
      ),
      status: SHAREHOLDERS.status,
      active: SHAREHOLDERS.active,
    },
    {
      key: 'online-kyc',
      title: translate('onboarding.incorporation.stepThree.title'),
      content: (
        <Flex gap={20} align="flex-start" vertical>
          {translate('onboarding.incorporation.stepThree.description')}

          {(ONLINE_KYC.status === 'inProgress' || !ONLINE_KYC.status) &&
            ONLINE_KYC.active && (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  handleUpdateStep('UPLOAD_DOCUMENTS', 'online-kyc');
                }}
                loading={loading}
              >
                {translate('onboarding.buttonStart')}
              </Button>
            )}

          {ONLINE_KYC.status === 'errorsFound' && ONLINE_KYC.active && (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                handleUpdateStep('MESSAGE_ERROR_DOCUMENTS', 'online-kyc');
              }}
              loading={loading}
            >
              {translate('onboarding.buttonReview')}
            </Button>
          )}
        </Flex>
      ),
      badge: getStepContentByStatus(
        ONLINE_KYC.status,
        'onboarding.incorporation.stepThree.extra'
      ),
      status: ONLINE_KYC.status,
      active: ONLINE_KYC.active,
    },
    {
      key: 'verify-documents',
      title: translate('onboarding.incorporation.stepFour.title'),
      content: (
        <Flex gap={20} align="flex-start" vertical>
          {translate('onboarding.incorporation.stepFour.description')}

          {VERIFY_DOCUMENTS.status !== 'completed' &&
          VERIFY_DOCUMENTS.active ? (
            !allPeopleHaveEmails ? (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  handleUpdateStep('REVIEW_SERVICES', 'verify-documents');
                }}
                loading={loading}
              >
                {translate('onboarding.buttonStart')}
              </Button>
            ) : (
              <S.ButtonReview
                type="text"
                size="small"
                onClick={() => {
                  handleUpdateStep(
                    'INVITE_SHAREHOLDERS_MANAGERS',
                    'verify-documents'
                  );
                }}
                loading={loading}
              >
                {translate('onboarding.inviteShareholders.review')}
              </S.ButtonReview>
            )
          ) : null}
        </Flex>
      ),
      badge: getStepContentByStatus(
        VERIFY_DOCUMENTS.status,
        'onboarding.incorporation.stepFour.extra'
      ),
      status: VERIFY_DOCUMENTS.status,
      active: VERIFY_DOCUMENTS.active,
    },
    {
      key: 'payment',
      title: translate('onboarding.incorporation.stepFive.title'),
      content: (
        <Flex gap={20} align="flex-start" vertical>
          {translate('onboarding.incorporation.stepFive.description')}

          {BANK_TRANSFERRING.status !== 'completed' &&
            !incorporationDetails?.isPaid &&
            BANK_TRANSFERRING.active && (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  handleUpdateStep('PAYMENT', 'payment');
                }}
                loading={loading}
              >
                {translate('onboarding.buttonStart')}
              </Button>
            )}

          {BANK_TRANSFERRING.status === 'inProgress' &&
            incorporationDetails?.companyType === 'sarl' &&
            incorporationDetails?.isPaid &&
            (incorporationDetails as Schemas.Incorporation)?.bankAccountData &&
            !incorporationDetails?.isTransferedShareCapital &&
            BANK_TRANSFERRING.active && (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  handleUpdateStep('MESSAGE_PROCESS_ACCOUNT_OPENED', 'payment');
                }}
                loading={loading}
              >
                {translate('onboarding.buttonContinue')}
              </Button>
            )}

          {BANK_TRANSFERRING.status === 'inProgress' &&
            incorporationDetails?.companyType === 'sarl-s' &&
            incorporationDetails?.isPaid &&
            BANK_TRANSFERRING.active && (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  handleUpdateStep('HONOR_DECLARATION', 'payment');
                }}
                loading={loading}
              >
                {translate('onboarding.buttonContinue')}
              </Button>
            )}
        </Flex>
      ),
      badge: getStepContentByStatus(BANK_TRANSFERRING.status),
      status: BANK_TRANSFERRING.status,
      active: BANK_TRANSFERRING.active,
    },
    {
      key: 'rcs',
      title: translate('onboarding.incorporation.stepSix.title'),
      content: translate('onboarding.incorporation.stepSix.description'),
      badge: getStepContentByStatus(REGISTRATION_IN_RCS.status),
      status: REGISTRATION_IN_RCS.status,
      active: REGISTRATION_IN_RCS.active,
    },
  ];

  const stepsListAccounting: StepEntity[] = [
    {
      key: 'companyName',
      title: translate('onboarding.accounting.stepOne.title'),
      content: (
        <Flex gap={20} align="flex-start" vertical>
          {translate('onboarding.accounting.stepOne.description')}

          {COMPANY_NAME.status !== 'completed' && COMPANY_NAME.active ? (
            <Button
              type="primary"
              size="small"
              onClick={() => updateScreen('COMPANY_NAME', { active: true })}
              loading={loading}
            >
              {translate('onboarding.buttonStart')}
            </Button>
          ) : null}
        </Flex>
      ),
      badge: getStepContentByStatus(
        COMPANY_NAME.status,
        'onboarding.accounting.stepOne.extra'
      ),
      status: COMPANY_NAME.status,
      active: COMPANY_NAME.active,
    },
    {
      key: 'shareholders',
      title: translate('onboarding.accounting.stepTwo.title'),
      content: (
        <Flex gap={20} align="flex-start" vertical>
          {translate('onboarding.accounting.stepTwo.description')}

          {SHAREHOLDERS.status !== 'completed' && SHAREHOLDERS.active ? (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                handleUpdateStep(
                  isIncorporation ? 'COMPANY_TYPE' : 'ACCOUNTING_PLAN',
                  'shareholders'
                );
              }}
              loading={loading}
            >
              {translate('onboarding.buttonStart')}
            </Button>
          ) : null}
        </Flex>
      ),
      badge: getStepContentByStatus(
        SHAREHOLDERS.status,
        'onboarding.accounting.stepTwo.extra'
      ),
      status: SHAREHOLDERS.status,
      active: SHAREHOLDERS.active,
    },
    {
      key: 'online-kyc',
      title: translate('onboarding.accounting.stepThree.title'),
      content: (
        <Flex gap={20} align="flex-start" vertical>
          {translate('onboarding.accounting.stepThree.description')}

          {(ONLINE_KYC.status === 'inProgress' || !ONLINE_KYC.status) &&
            ONLINE_KYC.active && (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  handleUpdateStep('UPLOAD_DOCUMENTS', 'online-kyc');
                }}
                loading={loading}
              >
                {translate('onboarding.buttonStart')}
              </Button>
            )}

          {ONLINE_KYC.status === 'errorsFound' && ONLINE_KYC.active && (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                handleUpdateStep('MESSAGE_ERROR_DOCUMENTS', 'online-kyc');
              }}
              loading={loading}
            >
              {translate('onboarding.buttonReview')}
            </Button>
          )}
        </Flex>
      ),
      badge: getStepContentByStatus(
        ONLINE_KYC.status,
        'onboarding.accounting.stepThree.extra'
      ),
      status: ONLINE_KYC.status,
      active: ONLINE_KYC.active,
    },
    {
      key: 'verify-documents',
      title: translate('onboarding.accounting.stepFour.title'),
      content: (
        <Flex gap={20} align="flex-start" vertical>
          {translate('onboarding.accounting.stepFour.description')}

          {VERIFY_DOCUMENTS.status !== 'completed' &&
          VERIFY_DOCUMENTS.active ? (
            !allPeopleHaveEmails ? (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  handleUpdateStep(
                    'INVITE_SHAREHOLDERS_MANAGERS',
                    'verify-documents'
                  );
                }}
                loading={loading}
              >
                {translate('onboarding.buttonStart')}
              </Button>
            ) : (
              <Button
                type="text"
                size="small"
                onClick={() => {
                  handleUpdateStep(
                    'INVITE_SHAREHOLDERS_MANAGERS',
                    'verify-documents'
                  );
                }}
                loading={loading}
              >
                {translate('onboarding.inviteShareholders.review')}
              </Button>
            )
          ) : null}
        </Flex>
      ),
      badge: getStepContentByStatus(
        VERIFY_DOCUMENTS.status,
        'onboarding.accounting.stepFour.extra'
      ),
      status: VERIFY_DOCUMENTS.status,
      active: VERIFY_DOCUMENTS.active,
    },
  ];

  const stepsList = isIncorporation
    ? stepsListIncorporation
    : stepsListAccounting;

  if (!screens.INCORPORATION.active) {
    return null;
  }

  return (
    <>
      {isIncorporation ? (
        <OnboardingCardHeader
          title={translate('onboarding.incorporation.title')}
          description={
            <>
              <Paragraph>
                {translate('onboarding.incorporation.description')}
              </Paragraph>

              <Paragraph strong>
                {translate('onboarding.incorporation.processDays')}
              </Paragraph>
            </>
          }
        />
      ) : (
        <OnboardingCardHeader
          title={translate('onboarding.accounting.title')}
          description={
            <>
              <Paragraph>
                {translate('onboarding.accounting.description')}
              </Paragraph>

              <Paragraph strong>
                {translate('onboarding.accounting.processDays')}
              </Paragraph>
            </>
          }
        />
      )}

      <S.Container>
        {stepsList
          .filter((step) => step.key)
          .map(({ active, status, isDivider, ...step }, index) => (
            <Fragment key={step.key}>
              {isDivider ? (
                <S.Divider
                  key={step.key}
                  justify="center"
                  align="center"
                  gap={10}
                >
                  {translate('onboarding.stepsForOnlyWebVersion')}
                </S.Divider>
              ) : (
                <S.Step active={active} status={status}>
                  {breakpoint.sm ? (
                    <Flex align="center" justify="space-between" gap={12}>
                      <Flex align="center" gap={12}>
                        {status === 'completed' && (
                          <S.StepNumber status={status} active={active}>
                            <IconCheck />
                          </S.StepNumber>
                        )}

                        {status === 'errorsFound' && (
                          <S.StepNumber status={status}>
                            <IconWarningTransparent />
                          </S.StepNumber>
                        )}

                        {(status === 'inProgress' || status === 'onReview') && (
                          <S.StepNumber status={status} active>
                            <IconClock />
                          </S.StepNumber>
                        )}

                        {!status && (
                          <S.StepNumber status={status} active={active}>
                            {index + 1}
                          </S.StepNumber>
                        )}

                        <S.Title>{step.title}</S.Title>
                      </Flex>

                      {step.badge && (
                        <>
                          {!status ? (
                            <StatusTag
                              status={active ? 'primary' : null}
                              label={step.badge}
                              small
                            />
                          ) : (
                            <StatusTag status={status} small />
                          )}
                        </>
                      )}
                    </Flex>
                  ) : (
                    <Flex gap={20} vertical>
                      <Flex align="center" justify="space-between" gap={12}>
                        <Flex align="center" gap={12}>
                          {status === 'completed' && (
                            <S.StepNumber status={status} active={active}>
                              <IconCheck />
                            </S.StepNumber>
                          )}

                          {status === 'errorsFound' && (
                            <S.StepNumber status={status}>
                              <IconWarningTransparent />
                            </S.StepNumber>
                          )}

                          {(status === 'inProgress' ||
                            status === 'onReview') && (
                            <S.StepNumber status={status} active>
                              <IconClock />
                            </S.StepNumber>
                          )}

                          {!status && (
                            <S.StepNumber status={status} active={active}>
                              {index + 1}
                            </S.StepNumber>
                          )}
                        </Flex>

                        {step.badge && (
                          <>
                            {!status ? (
                              <StatusTag
                                status={active ? 'primary' : null}
                                label={step.badge}
                                small
                              />
                            ) : (
                              <StatusTag status={status} small />
                            )}
                          </>
                        )}
                      </Flex>

                      <S.Title>{step.title}</S.Title>
                    </Flex>
                  )}

                  {step.content &&
                    (breakpoint.sm || step.key === 'companyName') && (
                      <S.Content>{step.content}</S.Content>
                    )}
                </S.Step>
              )}
            </Fragment>
          ))}

        <S.Completed
          completed={
            INCORPORATED.active || BANK_TRANSFERRING.status === 'completed'
          }
          onClick={maybeGoToTransactions}
        >
          <IconBag />

          {isIncorporation
            ? INCORPORATED.active || BANK_TRANSFERRING.status === 'completed'
              ? translate('onboarding.companyRegistered.completed')
              : translate('onboarding.companyRegistered.progress')
            : translate('onboarding.companyAccounting.completed')}
        </S.Completed>
      </S.Container>
    </>
  );
};

export default OnboardingOverview;
