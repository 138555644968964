import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { LoginForm, PageMeta, PublicCardForm } from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useAuthLogIn } from '@hooks-api';

const Login: FC = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const { updateToken, setAccount, company, userAccess } = useAccount();

  const { mutate: logIn, isLoading: loading } = useAuthLogIn();

  const [error, setError] = useState('');

  const handleSubmit = (values: Schemas.LoginDto) => {
    logIn(
      { requestBody: values },
      {
        onSuccess: (response) => {
          updateToken(response.accessToken);
          setAccount(response.user);

          if (company?.status === 'incorporating') {
            navigate('/start');
          } else {
            navigate(userAccess?.transactions ? '/transactions' : '/documents');
          }
        },
        onError: (error) =>
          setError((error.response?.data?.message || [])[0] || ''),
      }
    );
  };

  return (
    <>
      <PageMeta title={translate('auth.login.title')} />

      <PublicCardForm
        title={translate('auth.login.title')}
        subTitle={translate('auth.login.subtitle')}
      >
        <LoginForm
          onSubmit={handleSubmit}
          onError={setError}
          error={error}
          isLoading={loading}
        />
      </PublicCardForm>
    </>
  );
};

export default Login;
