import { FC, PropsWithChildren, ReactNode } from 'react';

import * as S from './styled';

type CardProps = {
  title?: ReactNode;
  className?: string;
  padding?: number;
  containerId?: string;
  minHeight?: number;
  minHeightSuffix?: 'vh' | 'px' | '%';
  isGrey?: boolean;
  noShadow?: boolean;
};

const Card: FC<PropsWithChildren<CardProps>> = ({
  title,
  children,
  padding,
  minHeight,
  className,
  containerId = undefined,
  minHeightSuffix = 'px',
  isGrey,
  noShadow,
}) => (
  <S.Container
    className={className}
    padding={padding}
    id={containerId}
    minHeight={minHeight}
    minHeightSuffix={minHeightSuffix}
    isGrey={isGrey}
    noShadow={noShadow}
  >
    {title && <S.Title>{title}</S.Title>}
    {children}
  </S.Container>
);

export default Card;
