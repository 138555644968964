import {
  Button,
  Flex,
  Table,
  type TableColumnType,
  Tooltip,
  message,
} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAccountsController_getAccounts } from '@api-client/generated/AccountsController/getAccounts';
import { useTransactionController_findAll } from '@api-client/generated/TransactionController/findAll';
import { Schemas } from '@api-client/generated/types';
import { IconMissedFile } from '@assets';
import { ReportType } from '@constants';
import { useModalManager } from '@context/ModalManager';
import {
  Amount,
  EmptyTransactions,
  FilterConstructor,
  type FilterConstructorProps,
  FilterControl,
  TransactionContentModal,
} from '@entities';
import { useAccount } from '@hooks';
import useUpdateTransactionById from '@hooks/api/transactions/useUpdateTransactionById';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';
import { colors } from '@theme';
import {
  getDateDefault,
  getSearchParams,
  historyReplace,
  setSearchParams,
} from '@utils';

import { ContactCell } from './ContactCell';
import * as S from './styled';

type Transaction = Schemas.Transaction;
type FilterOptions = Record<string, unknown>;

type TransactionsTableProps = {
  title?: ReactNode;
  filterParams?: Partial<FilterConstructorProps<Transaction>>;
  baseOptions?: FilterOptions;
  enabledRequest?: boolean;
  disabledConfig?: boolean;
  disabledRedirect?: boolean;
  hiddenColumns?: string[];
  onAfterContentClose?: () => void;
};

const customIsEmpty = (value: unknown) =>
  value === null ||
  value === undefined ||
  value === '' ||
  (_.isArray(value) && value.length === 0) ||
  (_.isObject(value) && _.isEmpty(value));

const emptyHiddenColumns: string[] = [];

const useGetTableColumns = (
  filterOptions: FilterOptions,
  assignContact: (transactionId: string, contact: Schemas.Contact) => void,
  onRulesSubmit: () => void,
  hiddenColumns = emptyHiddenColumns
): TableColumnType<Transaction>[] => {
  const { userAccess } = useAccount();

  const columns = useMemo(
    (): TableColumnType<Transaction>[] => [
      userAccess?.incomesExpences
        ? {
            key: 'file',
            title: '',
            width: 40,
            align: 'center',
            render: (row: Transaction) =>
              !row.documents?.length &&
              !row.isFileless && (
                <Tooltip
                  title={t('transactionsPage.missedPaymentDocument')()}
                  placement="right"
                >
                  <Button
                    icon={<IconMissedFile color={colors.error} />}
                    type="text"
                    size="small"
                  />
                </Tooltip>
              ),
            onHeaderCell: () => ({
              style: {
                paddingInline: 0,
              },
            }),
            onCell: () => ({
              style: {
                paddingInline: 0,
              },
            }),
            hidden: hiddenColumns.includes('file'),
          }
        : {},

      {
        key: 'amount',
        dataIndex: 'amount',
        title: t('transactionsPage.label.amount'),
        align: 'right',
        width: 130,
        render: (amount, record) => (
          <Amount amount={amount} currencyCode={record.currencyCode} />
        ),
        hidden: hiddenColumns.includes('amount'),
      },
      {
        key: 'contact',
        dataIndex: 'contact',
        title: t('transactionsPage.label.contact')(),
        width: 150,
        render: (contact, record) => ({
          children: (
            <ContactCell
              contact={contact}
              assignContact={(contact) => assignContact(record.id, contact)}
              onRulesSubmit={onRulesSubmit}
            />
          ),
        }),
        ellipsis: true,
        hidden: hiddenColumns.includes('contact'),
      },
      {
        key: 'details',
        dataIndex: 'details',
        title: t('transactionsPage.label.details')(),
        ellipsis: true,
        hidden: hiddenColumns.includes('details'),
      },
      {
        key: 'category',
        dataIndex: 'category',
        title: t('transactionsPage.label.category')(),
        width: 200,
        render: (category) => category?.name,
        ellipsis: true,
        hidden: hiddenColumns.includes('category'),
      },
      {
        key: 'account',
        dataIndex: 'account',
        title: t('transactionsPage.label.bank')(),
        render: (account: Schemas.Account) =>
          account?.connection?.bank?.name || account?.accountName,
        width: 150,
        ellipsis: true,
        hidden: hiddenColumns.includes('account'),
      },
      {
        key: 'date',
        dataIndex:
          filterOptions.showByPlDate === ReportType.ProfitsAndLosses
            ? 'plDate'
            : 'bookingDate',
        title: t('transactionsPage.label.date')(),
        width: 110,
        align: 'right',
        render: (date) => getDateDefault(date),
        hidden: hiddenColumns.includes('date'),
      },
      {
        key: 'plDate',
        dataIndex: 'plDate',
        title: t('transactionsPage.label.plDate')(),
        width: 110,
        align: 'right',
        render: (date, record) =>
          record.isIgnoredForPL ? (
            <S.ExcludedTransaction>
              {t('transactionsPage.excluded')()}
            </S.ExcludedTransaction>
          ) : (
            date && dayjs(date).format('MMM YYYY')
          ),
        hidden: hiddenColumns.includes('plDate'),
      },
    ],
    [
      userAccess?.incomesExpences,
      hiddenColumns,
      filterOptions.showByPlDate,
      assignContact,
      onRulesSubmit,
    ]
  );

  return columns;
};

const controls: { left: FilterControl[] } = {
  left: [],
};

const TransactionsTable: FC<TransactionsTableProps> = ({
  title,
  filterParams,
  baseOptions,
  enabledRequest,
  hiddenColumns,
  disabledConfig,
  disabledRedirect,
  onAfterContentClose,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { companyId, userAccess } = useAccount();

  const searchParams = getSearchParams<FilterOptions>(search);

  const [filterOptions, setFilterOptions] =
    useState<FilterOptions>(searchParams);
  const [filterParamsReady, setFilterParamsReady] = useState(false);
  const [transactionModalOpen, setTransactionModalOpen] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    string | null
  >(null);
  const [selectedRow, setSelectedRow] = useState<string>();
  const [detailsPage, setDetailsPage] = useState(1);
  const [tableColumns, setTableColumns] = useState<
    TableColumnType<Transaction>[]
  >([]);
  const isTableClickable = !useModalManager().isOpen();

  const {
    page,
    incrementPage,
    setTotalPages,
    hasNextPage,
    plainData,
    appendData,
    reset,
    UNSAFE_setData: setTransactionsList,
  } = usePagination<Transaction>();

  const { isFetching: isLoading, refetch } = useTransactionController_findAll({
    params: {
      ...filterOptions,
      companyId: companyId!,
      page,
      reportType:
        filterOptions?.showByPlDate == 'true' ||
        (typeof filterOptions?.showByPlDate === 'boolean' &&
          filterOptions?.showByPlDate)
          ? 'pl'
          : 'cf',
      ...baseOptions,
    },
    config: {
      enabled: enabledRequest || filterParamsReady,
      onSuccess: ({ records, metadata }) => {
        setTotalPages(metadata.totalPages);
        appendData(metadata.currentPage, records);
      },
    },
  });

  const { sentryRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  const { data: accounts, loading: isAccountsListLoading } =
    useAccountsController_getAccounts({
      params: {
        companyId: companyId!,
      },
    });

  const onRulesSubmit = useCallback(() => {
    reset();
    refetch();
  }, [refetch, reset]);

  useEffect(() => {
    reset();
  }, [reset, search]);

  useEffect(() => {
    if (userAccess && !userAccess?.transactions) {
      navigate('/documents');
    }
  }, [navigate, userAccess]);

  const handleRedirectToDetails = (e: MouseEvent, id: string) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/transactions/${id}`, '_blank');
    } else {
      historyReplace(`/transactions/${id}`);

      setSelectedTransactionId(id);
      setTransactionModalOpen(true);
    }
  };

  const handleCloseContentModal = () => {
    if (!disabledRedirect) {
      historyReplace(
        `/transactions?${setSearchParams<FilterOptions>(_.omitBy(filterOptions, customIsEmpty))}`
      );
    } else {
      onAfterContentClose?.();
    }

    setSelectedTransactionId(null);
    setTransactionModalOpen(false);
  };

  const rowClassName = (record: Schemas.Transaction) =>
    record.id === selectedRow ? 'highlight-row' : '';

  const handleClickRow = (
    record: Schemas.Transaction,
    index: number | undefined
  ) => ({
    onMouseDown: (e: MouseEvent) => {
      if (!isTableClickable) {
        return;
      }

      handleRedirectToDetails(e, record.id);
      setDetailsPage(index! + 1);
      setSelectedRow(record.id);
    },
  });

  // TODO fix indirect state modification
  const handleChangeFileIds = (id: string, value: Schemas.Document[] | []) => {
    setTransactionsList((prev) => {
      pages: for (const pageItems of Object.values(prev)) {
        for (const transaction of pageItems) {
          if (transaction.id === id) {
            transaction.documents = value;
            break pages;
          }
        }
      }

      return prev;
    });
  };

  // TODO fix indirect state modification
  const handleChangeSettings = (
    id: string,
    field: 'isFileless' | 'isIgnoredForPL',
    value: boolean
  ) => {
    setTransactionsList((prev) => {
      pages: for (const pageItems of Object.values(prev)) {
        for (const transaction of pageItems) {
          if (transaction.id === id) {
            transaction[field] = value;
            break pages;
          }
        }
      }

      return prev;
    });
  };

  const handleOnChange = useCallback(
    (values: FilterOptions) => setFilterOptions({ ...values }),
    []
  );

  const { mutate: updateTransaction } = useUpdateTransactionById();

  const assignContact = useCallback(
    (transactionId: string, contact: Schemas.Contact) => {
      updateTransaction(
        {
          parameter: {
            id: transactionId,
            companyId: companyId!,
          },
          requestBody: {
            contact,
          },
        },
        {
          onSuccess: () => {
            refetch();
            message.success(t('transactionsPage.contactWasChanged')());
          },
        }
      );
    },
    [companyId, refetch, updateTransaction]
  );

  const columns = useGetTableColumns(
    filterOptions,
    assignContact,
    onRulesSubmit,
    hiddenColumns
  );

  return (
    <>
      <Flex gap={24} vertical>
        {title}

        {selectedRow && (
          <TransactionContentModal
            id={selectedTransactionId}
            open={transactionModalOpen}
            onCancel={handleCloseContentModal}
            page={detailsPage}
            filterOptions={{ ...filterOptions, ...baseOptions }}
            setSelectedRow={setSelectedRow}
            onChangeTransactionFileList={handleChangeFileIds}
            handleChangeSettings={handleChangeSettings}
            onUpdateTransaction={refetch}
          />
        )}

        <FilterConstructor<Transaction>
          columns={columns}
          controls={controls}
          onChange={handleOnChange}
          onChangeColumns={setTableColumns}
          onReady={setFilterParamsReady}
          withSearchParams
          {...filterParams}
        />

        {!plainData.length && (isLoading || isAccountsListLoading) ? (
          <S.Loader />
        ) : (
          <>
            {plainData.length ? (
              <S.TableContainer>
                <Table
                  rowKey={({ id }) => id}
                  onRow={handleClickRow}
                  dataSource={plainData}
                  columns={disabledConfig ? columns : tableColumns}
                  loading={isLoading}
                  pagination={false}
                  scroll={{ x: 720 }}
                  rowClassName={rowClassName}
                />
                {hasNextPage && <div ref={sentryRef} />}
              </S.TableContainer>
            ) : (
              !accounts?.length && <EmptyTransactions />
            )}
          </>
        )}
      </Flex>
    </>
  );
};

export default TransactionsTable;
