import { Button, Flex, Typography } from 'antd';
import { FC, useState } from 'react';

import { OnboardingCardHeader, OnboardingRadioControl } from '@entities';
import { useAccount, useOnboarding } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

import * as S from './styled';

const { Paragraph } = Typography;

const OnboardingLegalAddress: FC = () => {
  const { updateScreen, updateIncorporation, incorporationDetails } =
    useOnboarding();

  const { mutate: update, isLoading: loading } = useUpdateIncorporation();

  const { companyId } = useAccount();

  const [registeredAddress, setRegisteredAddress] = useState<'my' | 'finding'>(
    incorporationDetails?.usesOwnRegisteredAddress ? 'my' : 'finding'
  );

  const handleNext = () => {
    if (registeredAddress === 'finding') {
      update(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            isSubmittedToKyc: true,
          },
        },
        {
          onSuccess: () => {
            updateIncorporation({ isSubmittedToKyc: true });
            updateScreen('MESSAGE_PROCESS_DOCUMENTS', { active: true });
          },
        }
      );
    }

    if (registeredAddress === 'my') {
      update(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            usesOwnRegisteredAddress: true,
          },
        },
        {
          onSuccess: () => {
            updateScreen('PROVIDE_ADDRESS', { active: true });
          },
        }
      );
    }
  };

  return (
    <S.Container>
      <S.Inner>
        <OnboardingCardHeader
          title={t('onboarding.legalAddress.title')()}
          description={
            <Paragraph strong>
              {t('onboarding.legalAddress.description')()}
            </Paragraph>
          }
        />

        <OnboardingRadioControl
          value={registeredAddress}
          setValue={setRegisteredAddress}
          controls={[
            {
              value: 'finding',
              title: t('onboarding.legalAddress.finding.title')(),
              content: t('onboarding.legalAddress.finding.description')(),
            },
            {
              value: 'my',
              title: t('onboarding.legalAddress.my.title')(),
              content: t('onboarding.legalAddress.my.description')(),
            },
          ]}
        />
      </S.Inner>

      <Flex justify="flex-end">
        <Button
          type="text"
          onClick={() => updateScreen('REVIEW_DOCUMENTS', { active: true })}
        >
          {t('onboarding.buttonGoBack')()}
        </Button>

        <Button type="primary" onClick={handleNext} loading={loading}>
          {t('onboarding.buttonNext')()}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingLegalAddress;
