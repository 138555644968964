import { Typography } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import {
  type FilterConstructorProps,
  PageMeta,
  TransactionsTable,
} from '@entities';
import { Button } from '@ui-kit/Button/Button';
import { IconUploadCsv } from '@ui-kit/Icon';

const Transactions = () => {
  const navigate = useNavigate();

  const filterParams: Partial<FilterConstructorProps<Schemas.Transaction>> =
    useMemo(
      () => ({
        controls: {
          left: [
            {
              type: 'search',
              formName: 'term',
            },
            {
              type: 'divider',
            },
            {
              type: 'module-filter',
              controls: [
                {
                  label: t('filter.category.label')(),
                  type: 'list-categories',
                  formName: 'categoryIds',
                },
                {
                  label: t('filter.contacts.label')(),
                  type: 'list-contacts',
                  formName: 'contactIds',
                },
                {
                  label: t('filter.bank.label')(),
                  type: 'list-banks',
                  formName: 'accountIds',
                },
                {
                  label: t('filter.project.label')(),
                  type: 'list-projects',
                  formName: 'projectIds',
                },
                {
                  // TODO fix issue with false parsed as a string on FE and producing filtered result after toggling on and the toggling off
                  label: t('transactionsPage.label.withoutDocuments')(),
                  type: 'switch',
                  formName: 'onlyWithoutDocuments',
                },
                {
                  // TODO fix issue with false parsed as a string on FE and producing filtered result after toggling on and the toggling off
                  label: t('transactionsPage.label.byPlDate')(),
                  type: 'switch',
                  formName: 'showByPlDate',
                  isBoolean: true,
                },
              ],
            },
            {
              type: 'range-picker',
              formName: 'date',
            },
          ],
          right: [
            {
              type: 'module-sort',
            },
            {
              type: 'module-columns-config',
            },
            {
              type: 'custom-component',
              component: (
                <Button
                  icon={<IconUploadCsv />}
                  color="primary"
                  variant="filled"
                  size="small"
                  onClick={() => navigate('/accounts')}
                />
              ),
            },
          ],
        },
      }),
      [navigate]
    );

  return (
    <>
      <PageMeta title={t('transactionsPage.title')()} />

      <TransactionsTable
        title={
          <Typography.Title>{t('transactionsPage.title')()}</Typography.Title>
        }
        filterParams={filterParams}
      />
    </>
  );
};

export default Transactions;
