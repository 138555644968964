import { Button, Flex, Form, Input, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { Card, CopyButton } from '@components';
import countries from '@data/countries.json';
import { StatusCircle } from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useMarkPaidTaxById } from '@hooks-api';

import * as S from './styled';

type TaxProvideProps = {
  tax: Schemas.Tax | null;
};

const TaxProvide: FC<TaxProvideProps> = ({ tax }) => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();
  const { id: taxId } = useParams();

  const [form] = Form.useForm();

  const [taxDetails, setTaxDetails] = useState<Schemas.Tax | null>(null);

  const { mutate: markPaid, isLoading } = useMarkPaidTaxById();

  useEffect(() => {
    if (tax) {
      form.setFieldsValue({
        ...tax,
        details: {
          ...tax.details,
          countryCode: getCountryByCode(tax.details.countryCode),
        },
        periodType: 'quarter',
      });

      setTaxDetails(tax);
    }
  }, [form, tax]);

  const handleMarkPaid = () => {
    markPaid(
      {
        parameter: {
          companyId: companyId!,
          id: taxId!,
        },
      },
      {
        onSuccess: (tax) => {
          message.success(translate('taxes.messageUpdatedSuccess'));

          setTaxDetails(tax);
        },
      }
    );
  };

  const getCountryByCode = (code: string) => {
    const country = countries.filter((country) => country.code === code);

    return country ? country[0]?.name : '';
  };

  return (
    <Card>
      <Flex align="center" gap={12}>
        <StatusCircle number={2} active />
        <S.Title>{translate('taxes.provide.name')}</S.Title>
      </Flex>

      <S.Details>{translate('taxes.provide.details')}</S.Details>

      <S.Form>
        <Form form={form} layout="vertical" requiredMark={false}>
          <Form.Item
            label={translate('taxes.provide.country.label')}
            name={['details', 'countryCode']}
          >
            <Input
              placeholder={translate('taxes.provide.country.label')}
              size="large"
              suffix={
                <CopyButton
                  value={getCountryByCode(
                    taxDetails?.details.countryCode || ''
                  )}
                />
              }
              readOnly
            />
          </Form.Item>

          <Form.Item
            label={translate('taxes.provide.companyName.label')}
            name={['details', 'companyName']}
          >
            <Input
              placeholder={translate('taxes.provide.companyName.label')}
              size="large"
              suffix={<CopyButton value={taxDetails?.details.companyName} />}
              readOnly
            />
          </Form.Item>

          <Form.Item
            label={translate('taxes.provide.iban.label')}
            name={['details', 'iban']}
          >
            <Input
              placeholder={translate('taxes.provide.iban.label')}
              size="large"
              suffix={<CopyButton value={taxDetails?.details.iban} />}
              readOnly
            />
          </Form.Item>

          <Form.Item
            label={translate('taxes.provide.bic.label')}
            name={['details', 'bic']}
          >
            <Input
              placeholder={translate('taxes.provide.bic.label')}
              size="large"
              suffix={<CopyButton value={taxDetails?.details.bic} />}
              readOnly
            />
          </Form.Item>

          <Form.Item
            label={translate('taxes.provide.address.label')}
            name={['details', 'addressLine']}
          >
            <Input
              placeholder={translate('taxes.provide.address.label')}
              size="large"
              suffix={<CopyButton value={taxDetails?.details.addressLine} />}
              readOnly
            />
          </Form.Item>

          <Form.Item
            label={translate('taxes.provide.reference.label')}
            name={['details', 'reference']}
          >
            <Input
              placeholder={translate('taxes.provide.reference.label')}
              size="large"
              suffix={<CopyButton value={taxDetails?.details.reference} />}
              readOnly
            />
          </Form.Item>

          {!taxDetails?.paidAt && (
            <S.Submit>
              <Form.Item noStyle>
                <Button
                  type="primary"
                  onClick={handleMarkPaid}
                  loading={isLoading}
                >
                  {translate('taxes.buttonMark')}
                </Button>
              </Form.Item>
            </S.Submit>
          )}
        </Form>
      </S.Form>
    </Card>
  );
};

export default TaxProvide;
