import { Button, Flex, Form, Modal, Select, Typography } from 'antd';
import React, { FC, useEffect, useState } from 'react';

import { useAccount } from '@hooks';
import { useGetCompanyById, useUpdateCompanyById } from '@hooks/api';
import { validateEmail } from '@utils';

const { Text } = Typography;

type BillingHistoryEmailModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const emailValidator = (_: unknown, value: string[]) => {
  if (value.some((email) => !validateEmail(email))) {
    return Promise.reject(
      new Error(
        t(
          'settings.billing.paymentDetails.billingEmail.modal.error'
        )()
      )
    );
  }

  return Promise.resolve();
}

const BillingHistoryEmailModal: FC<BillingHistoryEmailModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { companyId } = useAccount();
  const [input, setInput] = useState('');
  const [isFormDisabled, setFormDisabled] = useState(false);

  const { company } = useGetCompanyById({
    params: {
      companyId: companyId!,
    },
  });
  const { mutateAsync: patchCompany } = useUpdateCompanyById();

  const [form] = Form.useForm();

  const values = Form.useWatch([], form);

  useEffect(() => {
    form.setFieldsValue({
      email: company?.billingEmails,
    });
  }, [form, company]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setFormDisabled(false))
      .catch(() => setFormDisabled(true));
  }, [form, values]);

  const handleAddOption = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (input.trim() === '') {
        return;
      }

      const { email } = form.getFieldsValue(['email']);
      form.setFieldsValue({
        email: [...(email ?? []), input],
      });
      setInput('');
      await form.validateFields();
    }
  };

  const handleSubmit = async (data: { email: string[] }) => {
    if (!companyId) {
      return;
    }

    await patchCompany({
      parameter: {
        companyId,
      },
      requestBody: {
        billingEmails: data.email,
      },
    });

    onClose();
  };

  return (
    <Modal
      open={isOpen}
      title={t('settings.billing.paymentDetails.billingEmail.modal.title')()}
      width={764}
      onCancel={onClose}
      footer={null}
      centered
      destroyOnClose
    >
      <Flex gap={24} vertical>
        <Text>
          {t(
            'settings.billing.paymentDetails.billingEmail.modal.description'
          )()}
        </Text>
        <Form
          form={form}
          requiredMark={false}
          layout="vertical"
          initialValues={{
            email: company?.billingEmails,
          }}
          onFinish={handleSubmit}
        >
          <Flex gap={24} vertical>
            <Form.Item
              name="email"
              label={t(
                'settings.billing.paymentDetails.billingEmail.modal.label'
              )()}
              rules={[
                {
                  required: true,
                  validator: emailValidator,
                },
              ]}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                dropdownRender={() => <></>}
                tokenSeparators={[',', ' ']}
                dropdownStyle={{ display: 'none' }}
                searchValue={input}
                onSearch={setInput}
                suffixIcon={null}
                autoClearSearchValue={false}
                size="large"
                onKeyDown={handleAddOption}
                showSearch
              />
            </Form.Item>
            <Flex gap={12} justify="flex-end">
              <Button onClick={onClose}>
                {t(
                  'settings.billing.paymentDetails.billingEmail.modal.actions.cancel'
                )()}
              </Button>
              <Button
                disabled={isFormDisabled}
                htmlType="submit"
                type="primary"
              >
                {t(
                  'settings.billing.paymentDetails.billingEmail.modal.actions.save'
                )()}
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Flex>
    </Modal>
  );
};

export default BillingHistoryEmailModal;
