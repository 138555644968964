import { Flex, message } from 'antd';
import { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useAccountsController_getAccounts } from '@api-client/generated/AccountsController/getAccounts';
import { Loader } from '@components';
import {
  AccountAddModal,
  GoBack,
  PageMeta,
  TransactionsImportSidebar,
  TransactionsImportTable,
  TransactionsImportValidatedTable,
} from '@entities';
import { useAccount, useTransactionsImport, useTranslate } from '@hooks';
import {
  useGetFileMappingsById,
  useImportFileMappingsById,
  useUpdateFileMappingsById,
  useValidateFileMappingsById,
} from '@hooks-api';

import * as S from './styled';

const TransactionsImport: FC = () => {
  const navigate = useNavigate();
  const { id: accountId } = useParams();
  const { translate } = useTranslate();
  const { companyId } = useAccount();
  const { id } = useParams();

  const {
    isPreviewVisible,
    preview,
    reviewMapping,
    setMapping,
    setPreview,
    setPreviewVisible,
    setFileUploaded,
  } = useTransactionsImport();
  const [isAccountAddOpen, setIsAccountAddOpen] = useState(false);

  const { mutate: updateFileMappings, isLoading: loadingUpdate } =
    useUpdateFileMappingsById();
  const {
    mutate: validateFileMappings,
    isLoading: loadingValidate,
    data: validatedData,
  } = useValidateFileMappingsById();
  const { mutate: importFileMappings, isLoading: loadingImport } =
    useImportFileMappingsById();

  const { refetch: refetchAccounts } = useAccountsController_getAccounts({
    params: {
      companyId: companyId!,
    },
  });

  const { loading: getFileLoading } = useGetFileMappingsById({
    params: {
      companyId: companyId!,
      id: id!,
    },
    config: {
      refetchOnWindowFocus: false,
      onSuccess: ({ mapping, preview }) => {
        setFileUploaded(true);
        setMapping(mapping, true);
        setPreview(preview);
      },
    },
  });

  const handleReview = () => {
    updateFileMappings(
      {
        parameter: {
          id: accountId!,
          companyId: companyId!,
        },
        requestBody: {
          accountId: accountId!,
          mapping: reviewMapping,
        },
      },
      {
        onSuccess: (response) => {
          validateFileMappings(
            {
              parameter: {
                id: response.id!,
                companyId: companyId!,
              },
              requestBody: {},
            },
            {
              onSuccess: () => {
                setPreviewVisible(true);
              },
            }
          );
        },
      }
    );
  };

  const handleImport = () =>
    importFileMappings(
      {
        parameter: {
          id: accountId!,
          companyId: companyId!,
        },
      },
      {
        onSuccess: (response) => {
          if (response.transactionsAmount === 0) {
            message.warning({
              type: 'success',
              content: (
                <Trans
                  i18nKey={translate(
                    'transactionsImport.tableResult.successImportedButDublicate'
                  )}
                />
              ),
            });
          } else {
            message.open({
              type: 'success',
              content: (
                <Trans
                  i18nKey={translate(
                    'transactionsImport.tableResult.successImported'
                  )}
                  components={[<strong>{response.transactionsAmount}</strong>]}
                />
              ),
            });
          }

          navigate('/transactions');
        },
      }
    );

  const handleCancelAccountAdd = () => {
    refetchAccounts();
    setIsAccountAddOpen(false);
  };

  if (getFileLoading) {
    return (
      <S.LoaderWrapper>
        <Loader />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.Wrapper>
      <PageMeta title={translate('transactionsImport.titleImport')} />
      <S.Container gap={30} vertical>
        <AccountAddModal
          open={isAccountAddOpen}
          onCancel={handleCancelAccountAdd}
        />
        <Flex gap={16} vertical>
          {!isPreviewVisible ? <GoBack to="/accounts" /> : <div />}

          <S.Title>
            {!isPreviewVisible
              ? translate('transactionsImport.titleImport')
              : translate('transactionsImport.titlePreview')}
          </S.Title>
        </Flex>

        {preview && !isPreviewVisible && (
          <Flex gap={40}>
            <TransactionsImportTable result={validatedData} />
          </Flex>
        )}

        {isPreviewVisible && (
          <TransactionsImportValidatedTable result={validatedData} />
        )}
      </S.Container>

      <TransactionsImportSidebar
        onReview={handleReview}
        onImport={handleImport}
        onBack={() => setPreviewVisible(false)}
        validatingResult={validatedData}
        isPreviewVisible={isPreviewVisible}
        loading={loadingImport || loadingValidate || loadingUpdate}
      />
    </S.Wrapper>
  );
};

export default TransactionsImport;
