import { Flex } from 'antd';
import _ from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';
import { Scrollbar } from '@components';
import {
  ContactAction,
  ContactsCard,
  ContactsList,
  FilterConstructor,
  FilterConstructorDrawer,
  FilterControl,
  PageMeta,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useGetContactById, useGetContactsList } from '@hooks-api';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';
import { Button } from '@ui-kit/Button/Button';
import { getSearchParams } from '@utils';

import * as S from './styled';

type Contact = Schemas.Contact;
type FilterOptions = Record<string, unknown>;
type ContactAction = 'create' | 'edit';

const controls: { left: FilterControl[] } = {
  left: [
    {
      type: 'search',
      formName: 'term',
      params: {
        width: 340,
      },
    },
  ],
};

const filterControls = ['onlyWithoutTransactions'];

const Contacts: FC = () => {
  const { search } = useLocation();
  const { id: contactId } = useParams();
  const navigate = useNavigate();

  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const searchParams = getSearchParams<FilterOptions>(search);

  const [modalActionVisible, setModalActionVisible] = useState(false);
  const [modalActionType, setModalActionType] =
    useState<ContactAction>('create');

  const [filterOptions, setFilterOptions] =
    useState<FilterOptions>(searchParams);

  const [filterDrawerOptions, setFilterDrawerOptions] = useState<
    Record<string, unknown> | string[] | null
  >(null);

  const [transactionsCount, setTransactionsCount] = useState(0);
  const [filesCount, setFilesCount] = useState(0);

  const {
    page,
    incrementPage,
    setTotalPages,
    hasNextPage,
    plainData,
    appendData,
    reset,
  } = usePagination<Contact>();

  const { isFetching: fetchingContacts, refetch: refetchList } =
    useGetContactsList({
      params: {
        companyId: companyId!,
        page,
        ...filterOptions,
      },
      config: {
        refetchOnWindowFocus: false,
        onSuccess: ({ records, metadata }) => {
          setTotalPages(metadata.totalPages);
          appendData(metadata.currentPage, records);

          handleUpdateCount(contactId!, records);

          if (!records.length && filterOptions.term) {
            navigate('/contacts');
          }
        },
      },
    });

  const {
    contact,
    isFetching,
    refetch: refetchById,
    loading: loadingDetails,
  } = useGetContactById({
    params: {
      companyId: companyId!,
      id: contactId!,
    },
    config: {
      enabled: !!contactId,
      refetchOnWindowFocus: false,
      onSuccess: () => handleUpdateCount(contactId!, plainData),
    },
  });

  const { sentryRef, rootRef } = useInfiniteScroll({
    isLoading: fetchingContacts,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  useEffect(() => {
    reset();
  }, [reset, search]);

  useEffect(() => {
    if (contactId) {
      refetchById();
    }
  }, [contactId, refetchById]);

  const handleAfterAction = ({ id }: { id: string }) => {
    refetchById();
    refetchList();

    navigate(`/contacts/${id}`);
  };

  const handleUpdateCount = (id: string, records: Contact[]) => {
    const details = records.filter((contact) => contact.id === id)[0];

    setTransactionsCount(details?.transactionsCount || 0);
    setFilesCount(details?.documentsCount || 0);
  };

  const handleActionContact = (type: ContactAction) => {
    setModalActionVisible(true);
    setModalActionType(type);
  };

  const handleOnChange = useCallback(
    (values: FilterOptions) => setFilterOptions({ ...values }),
    []
  );

  return (
    <Flex gap={24} vertical>
      <PageMeta title={translate('contactsPage.title')} />

      <ContactAction
        type={modalActionType}
        open={modalActionVisible}
        details={contact}
        onAfterAction={handleAfterAction}
        onCancel={() => setModalActionVisible(false)}
      />

      <Flex align="flex-start" gap={30}>
        <S.List>
          <Flex gap={24} vertical>
            <S.Header align="center" justify="space-between">
              <S.Title>{translate('contactsPage.title')}</S.Title>

              <Flex align="center" gap={16}>
                <FilterConstructorDrawer
                  initialParams={filterOptions}
                  controls={[
                    {
                      type: 'switch',
                      formName: 'onlyWithoutTransactions',
                      label: translate(
                        'contactsPage.filter.showWithoutTransactions'
                      ),
                    },
                  ]}
                  buttonParams={{
                    type: 'text',
                    size: 'small',
                  }}
                  onSubmit={setFilterDrawerOptions}
                />

                <Button
                  type="text"
                  icon={<IconPlus />}
                  onClick={() => handleActionContact('create')}
                  size="small"
                />
              </Flex>
            </S.Header>

            <FilterConstructor<Contact>
              externalParameters={filterDrawerOptions}
              controls={controls}
              filterControls={filterControls}
              onChange={handleOnChange}
              withSearchParams
            />

            <Scrollbar
              height="calc(100vh - 310px)"
              ref={rootRef}
              withoutPadding
            >
              <ContactsList
                selectedId={contactId!}
                dataSource={plainData}
                loading={fetchingContacts}
                isFilterParams={!_.isEmpty(filterOptions)}
                onAdd={() => handleActionContact('create')}
              />

              {hasNextPage && <div ref={sentryRef} />}
            </Scrollbar>
          </Flex>
        </S.List>

        <ContactsCard
          filesCount={filesCount}
          transactionsCount={transactionsCount}
          details={contact}
          loading={isFetching || loadingDetails}
          onEdit={() => handleActionContact('edit')}
          onAfterDelete={refetchList}
        />
      </Flex>
    </Flex>
  );
};

export default Contacts;
