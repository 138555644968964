import { Form, Input, message } from 'antd';
import { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { PageMeta, PublicCardForm } from '@entities';
import { useTranslate } from '@hooks';
import { useAuthUpdatePassword } from '@hooks-api';
import { Button } from '@ui-kit/Button/Button';

const ResetPassword: FC = () => {
  const [searchParams] = useSearchParams();
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const [passwordError, setPasswordError] = useState<string | null>();

  const { mutate: updatePassword, isLoading: loading } =
    useAuthUpdatePassword();

  const resetToken = searchParams.get('token');

  const handleSubmit = (values: Schemas.UpdatePasswordDto) =>
    updatePassword(
      {
        requestBody: values,
        parameter: {
          reset_token: resetToken!,
        },
      },
      {
        onSuccess: () => {
          message.open({
            type: 'success',
            content: translate('auth.resetPassword.successReset'),
          });

          navigate('/login');
        },
        onError: (error) => {
          for (const message of error.response?.data?.message || []) {
            if (message.startsWith('password.')) {
              setPasswordError(message);
            }
          }
        },
      }
    );

  return (
    <>
      <PageMeta title={translate('auth.resetPassword.title')} />
      <PublicCardForm
        title={translate('auth.resetPassword.title')}
        subTitle={translate('auth.resetPassword.subtitle')}
      >
        <Form onFinish={handleSubmit} layout="vertical" requiredMark={false}>
          <Form.Item
            label={translate('auth.resetPassword.password.label')}
            name="password"
            rules={[
              {
                required: true,
                message: translate('auth.resetPassword.password.error'),
              },
            ]}
            validateStatus={passwordError ? 'error' : ''}
            help={passwordError && translate(`common.errors.password.weak`)}
          >
            <Input.Password
              placeholder={translate('auth.resetPassword.password.placeholder')}
              size="large"
            />
          </Form.Item>

          <Form.Item
            label={translate('auth.resetPassword.passwordConfirmation.label')}
            name="passwordConfirmation"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: translate(
                  'auth.resetPassword.passwordConfirmation.error'
                ),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      translate(
                        'auth.resetPassword.passwordConfirmation.errorMatch'
                      )
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={translate(
                'auth.resetPassword.passwordConfirmation.placeholder'
              )}
              size="large"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
              block
            >
              {translate('auth.resetPassword.buttonSend')}
            </Button>
          </Form.Item>
        </Form>
      </PublicCardForm>
    </>
  );
};

export default ResetPassword;
