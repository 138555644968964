import { AxiosError } from 'axios';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import apiClient from '@api-client';

export type QueryOptions<R> = Omit<
  UseQueryOptions<R, AxiosError<unknown, any>, R, QueryKey>,
  'queryKey' | 'queryFn'
>;

type ApiClientProps<P, R> = {
  // Method name as specified in api-client/generated/client.ts
  method: keyof typeof apiClient;
  // A set of parameters required for a specific API request.
  params?: P;
  queryKey?: QueryKey;
  config?: QueryOptions<R>;
};

/**
 * @description Hook for working with the API client and processing methods
 *
 * @example
 *
 * import { Params$AccountsController_getAccounts } from '@api-client/generated/client';
 * import { Schemas } from '@api-client/generated/types';
 * import { useApiClient } from '@hooks';
 *
 * const { data, error, loading } = useApiClient<Params$AccountsController_getAccounts, Schemas.Account[]>({
 *   method: 'AccountsController_getAccounts',
 *   params: {
 *     parameter: {
 *       companyId, // got with hook: const { companyId } = useAccount()
 *     },
 *   },
 *   config: {} // QueryOptions from react-query library
 * });
 *
 */

export const useApiClient = <P = any, R = null>({
  queryKey,
  method,
  params,
  config = {},
}: ApiClientProps<P, R>): UseQueryResult<R, AxiosError> & {
  /** @deprecated Use `isLoading` instead */
  loading: boolean;
} => {
  const result = useQuery<R, AxiosError>(
    queryKey || [method, params],
    () => apiClient[method](params as any).then((res) => res as R),
    config
  );

  return {
    /** @deprecated Use `isLoading` instead */
    loading: result.isLoading,
    ...result,
  };
};

export default useApiClient;
