import { FC, useState } from 'react';

import { PopupDelete } from '@entities';
import { useTranslate } from '@hooks';

import * as S from './styled';

type ContactsAlertDeleteProps = {
  onDelete: () => void;
};

const ContactsAlertDelete: FC<ContactsAlertDeleteProps> = ({ onDelete }) => {
  const { translate } = useTranslate();

  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);

  return (
    <>
      <PopupDelete
        open={isOpenPopupConfirm}
        title={translate('contactsPage.deleteConfirm.title')}
        confirmParams={{
          text: translate('contactsPage.deleteConfirm.buttonConfirm'),
        }}
        onRemove={onDelete}
        onCancel={() => setIsOpenPopupConfirm(false)}
      />

      <S.Container align="center" gap={16}>
        <S.Label>{translate('contactsPage.alertDelete.label')}</S.Label>

        <S.ButtonDelete
          type="text"
          onClick={() => setIsOpenPopupConfirm(true)}
          size="small"
          danger
        >
          {translate('contactsPage.alertDelete.buttonDelete')}
        </S.ButtonDelete>
      </S.Container>
    </>
  );
};

export default ContactsAlertDelete;
