import { useEffect, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import { BankItem } from '@entities';
import { useGetBanksList } from '@hooks-api';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';

import * as S from './styled';

type BankListProps = {
  term: string;
  onBankConnect: (bank: Schemas.Bank) => void;
};

const BanksList = ({ term, onBankConnect }: BankListProps) => {
  const [selectedBank, setSelectedBank] = useState<Schemas.Bank | null>(null);

  const {
    page,
    incrementPage,
    setTotalPages,
    hasNextPage,
    plainData,
    appendData,
    reset,
  } = usePagination<Schemas.Bank>();

  const { isFetching: isLoading } = useGetBanksList({
    params: {
      page,
      term,
    },
    config: {
      onSuccess: ({ records, metadata }) => {
        setTotalPages(metadata.totalPages);
        appendData(metadata.currentPage, records);
      },
    },
  });

  const { sentryRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  useEffect(() => {
    reset();
  }, [reset, term]);

  const handleBankConnect = (bank: Schemas.Bank) => {
    setSelectedBank(bank);

    onBankConnect(bank);
  };

  return (
    <S.Grid>
      {plainData.map((bank) => (
        <BankItem
          isLoading={false}
          selectedBank={selectedBank}
          key={bank.id}
          logo={`./${bank.bic}.png`}
          bank={bank}
          onConnect={handleBankConnect}
        />
      ))}
      {hasNextPage && <div ref={sentryRef} />}
    </S.Grid>
  );
};

export default BanksList;
