import { type AxiosError } from 'axios';
import { useMutation } from 'react-query';

import apiClient from '@api-client';

type ApiClientMutationProps = {
  method: keyof typeof apiClient;
};

type ApiErrorData = {
  error: string;
  statusCode: number;
  message: string[];
};

type ApiError = {
  message: string[];
  data: ApiErrorData;
};

export const useApiClientMutation = <T = any, R = null>({
  method,
}: ApiClientMutationProps) =>
  useMutation<R, AxiosError<ApiError>, T>((params) =>
    apiClient[method](params as any).then((res) => res as any)
  );

export default useApiClientMutation;
