import {
  Breadcrumb,
  Checkbox,
  Flex,
  Form,
  Input,
  Typography,
  message,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DefaultOptionType } from 'antd/es/select';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { Loader, Select } from '@components';
import { PageMeta } from '@entities';
import { useAccount, useCountries, useTranslate } from '@hooks';
import { useGetCompanyById, useUpdateCompanyById } from '@hooks-api';
import { IconChevronRight } from '@ui-kit/Icon';

import * as S from './styled';

const { Title } = Typography;

const SettingsCompany: FC = () => {
  const { translate } = useTranslate();
  const [isSameAddress, setIsSameAddress] = useState(false);

  const [form] = Form.useForm();

  const { countries, filterOptionCountries } = useCountries();
  const { mutate: updateCompany, isLoading: updateCompanyLoading } =
    useUpdateCompanyById();
  const { companyId } = useAccount();

  const { company, loading } = useGetCompanyById({
    params: {
      companyId: companyId!,
    },
  });

  const handleSetSameAddress = (e: CheckboxChangeEvent) => {
    setIsSameAddress((prev) => !prev);
    if (e.target.checked) {
      const values = form.getFieldsValue();
      form.setFieldValue('postalAddress', values.billingAddress);
    }
    handleSubmit();
  };

  const countryFilterSort = (
    input: DefaultOptionType,
    option: DefaultOptionType
  ) =>
    (input?.name ?? '')
      ?.toLowerCase()
      .localeCompare((option?.name ?? '').toLowerCase());

  const initialValues = {
    personalSettings: {
      companyName: company?.name,
      vatId: company?.vatId,
      registrationNumber: company?.registrationNumber,
    },
    billingAddress: company?.billingAddress,
    postalAddress: company?.postalAddress,
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    updateCompany(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          name: values.personalSettings.companyName,
          vatId: values.personalSettings.vatId,
          registrationNumber: values.personalSettings.registrationNumber,
          billingAddress: values.billingAddress,
          postalAddress: isSameAddress
            ? values.billingAddress
            : values.postalAddress,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('settings.company.success'));
        },
        onError: () => {
          message.error(translate('settings.company.error'));
        },
      }
    );
  };

  if (loading) return <Loader fullscreen />;

  return (
    <Flex gap={32} vertical>
      <PageMeta title={translate('settings.company.title')} />

      <Breadcrumb
        separator={<IconChevronRight />}
        items={[
          {
            title: <Link to="/settings">{translate('settings.title')}</Link>,
          },
          {
            title: translate('settings.company.title'),
          },
        ]}
      />
      {updateCompanyLoading && (
        <S.LoaderContainer>
          <Loader />
        </S.LoaderContainer>
      )}

      <Title>{translate('settings.company.title')}</Title>
      <Form initialValues={initialValues} form={form}>
        <S.CompanyDetailsContainer>
          <S.BlockTitle>
            {translate('settings.companySettings.personalInfo')}
          </S.BlockTitle>
          <S.FlexInner gap={30}>
            <S.LargeFormItem
              label={translate('settings.companySettings.companyName')}
              name={['personalSettings', 'companyName']}
              rules={[
                {
                  required: true,
                  message: translate('settings.company.nameError'),
                },
              ]}
            >
              <Input size="large" value={company?.name} onBlur={handleSubmit} />
            </S.LargeFormItem>
            <S.LargeFormItem
              label={translate('settings.companySettings.vat')}
              name={['personalSettings', 'vatId']}
            >
              <Input size="large" onBlur={handleSubmit} />
            </S.LargeFormItem>
            <S.LargeFormItem
              label={translate('settings.companySettings.registrationNumber')}
              name={['personalSettings', 'registrationNumber']}
            >
              <Input size="large" onBlur={handleSubmit} />
            </S.LargeFormItem>
          </S.FlexInner>
        </S.CompanyDetailsContainer>

        <S.FlexInner gap={30}>
          <S.CompanyDetailsContainer>
            <S.BlockTitle>
              {translate('contactPage.billingAddress')}
            </S.BlockTitle>

            <S.LargeFormItem
              label={translate('contactPage.country')}
              name={['billingAddress', 'countryCode']}
            >
              <Select
                width={350}
                listWidth={350}
                size="large"
                options={countries}
                showSearch
                onChange={handleSubmit}
                filterOption={filterOptionCountries}
                filterSort={countryFilterSort}
              />
            </S.LargeFormItem>

            <S.LargeFormItem
              label={translate('contactPage.city')}
              name={['billingAddress', 'city']}
            >
              <Input size="large" onBlur={handleSubmit} />
            </S.LargeFormItem>

            <S.LargeFormItem
              label={translate('contactPage.address')}
              name={['billingAddress', 'addressLine']}
            >
              <Input size="large" onBlur={handleSubmit} />
            </S.LargeFormItem>

            <S.LargeFormItem
              label={translate('contactPage.postalCode')}
              name={['billingAddress', 'postalCode']}
            >
              <Input size="large" onBlur={handleSubmit} />
            </S.LargeFormItem>

            <Flex>
              <Checkbox onChange={handleSetSameAddress} />
              <S.Text>{translate('contactPage.sameAddresses')}</S.Text>
            </Flex>
          </S.CompanyDetailsContainer>

          <S.CompanyDetailsContainer>
            <S.BlockTitle>
              {translate('contactPage.postalAddress')}
            </S.BlockTitle>

            <S.LargeFormItem
              label={translate('contactPage.country')}
              name={['postalAddress', 'countryCode']}
            >
              <Select
                size="large"
                disabled={isSameAddress}
                width={350}
                listWidth={350}
                options={countries}
                showSearch
                filterOption={filterOptionCountries}
                filterSort={countryFilterSort}
                onBlur={handleSubmit}
              />
            </S.LargeFormItem>

            <S.LargeFormItem
              label={translate('contactPage.city')}
              name={['postalAddress', 'city']}
            >
              <Input
                disabled={isSameAddress}
                size="large"
                onBlur={handleSubmit}
              />
            </S.LargeFormItem>

            <S.LargeFormItem
              label={translate('contactPage.address')}
              name={['postalAddress', 'addressLine']}
            >
              <Input
                disabled={isSameAddress}
                size="large"
                onBlur={handleSubmit}
              />
            </S.LargeFormItem>

            <S.LargeFormItem
              label={translate('contactPage.postalCode')}
              name={['postalAddress', 'postalCode']}
            >
              <Input
                disabled={isSameAddress}
                size="large"
                onBlur={handleSubmit}
              />
            </S.LargeFormItem>
          </S.CompanyDetailsContainer>
        </S.FlexInner>
      </Form>
    </Flex>
  );
};

export default SettingsCompany;
