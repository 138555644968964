import {
  Button,
  Col,
  Flex,
  Form,
  Modal,
  type ModalProps,
  Row,
  Tooltip,
} from 'antd';
import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconInfo } from '@assets';
import { DeafSelect } from '@components';
import { DEFAULT_COUNTRY_CODE } from '@constants';
import {
  ContactVatIdControl,
  ContactsWrapperFormControl,
  SelectVatType,
} from '@entities';
import { useAccount, useCountries, useTranslate } from '@hooks';
import {
  useCreateContact,
  useGetCompanyByVatId,
  useUpdateContactById,
} from '@hooks-api';

import * as S from './styled';

type ContactDto = Schemas.ContactDto;

type ContactActionProps = {
  details?: Schemas.Contact | null;
  type?: 'create' | 'edit';
  onCancel: () => void;
  onAfterAction: (contact: Schemas.Contact) => void;
} & ModalProps;

const ContactAction: FC<ContactActionProps> = ({
  type = 'create',
  details,
  open,
  onCancel,
  onAfterAction,
  ...rest
}) => {
  const { id: contactId } = useParams();

  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const [form] = Form.useForm();

  const [isSelectTypeVisible, setIsSelectTypeVisible] = useState(false);
  const [selectedType, setSelectedType] = useState<string>();

  const [vatId, setVatId] = useState<string | null>(null);
  const [isValidVatId, setIsValidVatId] = useState(false);
  const [isErrorVatId, setIsErrorVatId] = useState(false);
  const [isNotValidVatId, setIsNotValidVatId] = useState(false);

  const { countries } = useCountries();

  const { isFetching: isProcessingVatId, refetch } = useGetCompanyByVatId({
    params: {
      vatId: vatId!,
    },
    config: {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setIsErrorVatId(false);
        setIsValidVatId(true);

        form.setFieldsValue({
          ...response,
          billingAddress: {
            ...response.address,
            addressLine: response.address.street,
          },
        });
      },
      onError: () => {
        setIsErrorVatId(true);
        setIsValidVatId(false);
      },
    },
  });

  const { mutate: createContact, isLoading: loadingCreate } =
    useCreateContact();
  const { mutate: updateContact, isLoading: loadingUpdate } =
    useUpdateContactById();

  const isTypeCreate = type === 'create';
  const isTypeEdit = type === 'edit';

  useEffect(() => {
    if (isTypeCreate) {
      form.resetFields();

      setVatId(null);
      setIsErrorVatId(false);
      setIsNotValidVatId(false);
    }
  }, [open, isTypeCreate, form]);

  useEffect(() => {
    if (isTypeEdit && details) {
      form.setFieldsValue({
        ...details,
        billingAddress: {
          ...(details.billingAddress || {}),
          countryCode:
            details.billingAddress?.countryCode || DEFAULT_COUNTRY_CODE,
        },
      });

      if (details.vatType) {
        setSelectedType(details.vatType);
      }
    }
  }, [details, type, isTypeEdit, form]);

  const onSuccess = (contact: Schemas.Contact) => {
    onAfterAction(contact);
    onCancel();
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (!isNotValidVatId) {
        refetch();
      }
    }
  };

  const handleValidateVatId = (e: ChangeEvent<HTMLInputElement>) => {
    setVatId(e.target.value);

    if (isValidVatId) {
      setIsValidVatId(false);
    }

    form
      .validateFields(['vatId'])
      .then(() => {
        setIsNotValidVatId(false);
        refetch();
      })
      .catch(() => setIsNotValidVatId(true));
  };

  const handleSubmit = (values: ContactDto) => {
    const body = {
      ...values,
    };

    if (selectedType) {
      body.vatType = selectedType;
    }

    if (isTypeCreate) {
      createContact(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: body,
        },
        {
          onSuccess,
        }
      );
    } else {
      updateContact(
        {
          parameter: {
            companyId: companyId!,
            id: contactId!,
          },
          requestBody: body,
        },
        {
          onSuccess,
        }
      );
    }
  };

  return (
    <Modal
      width={575}
      open={open}
      title={
        isTypeCreate
          ? translate('contactsPage.newContact.title')
          : translate('contactsPage.editContact.title')
      }
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <SelectVatType
        value={selectedType}
        open={isSelectTypeVisible}
        onCancel={() => setIsSelectTypeVisible(false)}
        onChange={setSelectedType}
      />

      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          billingAddress: {
            countryCode: DEFAULT_COUNTRY_CODE,
          },
        }}
      >
        <ContactVatIdControl
          isValid={isValidVatId}
          isNotValid={isNotValidVatId}
          isError={isErrorVatId}
          processing={isProcessingVatId}
          onChange={handleValidateVatId}
          onKeyPress={handleKeyPress}
        />

        <S.Card>
          <ContactsWrapperFormControl
            type="input"
            form={{
              label: translate('contactsPage.newContact.fieldCompany.label'),
              name: 'name',
              rules: [
                {
                  required: true,
                  message: translate(
                    'contactsPage.newContact.fieldCompany.errorMessage'
                  ),
                },
              ],
            }}
          />

          <Row gutter={[12, 0]}>
            <ContactsWrapperFormControl
              type="input"
              span={10}
              form={{
                label: (
                  <Flex align="center" gap={6}>
                    {translate('contactsPage.newContact.fieldTaxNumber.label')}

                    <Tooltip
                      title={translate(
                        'invoiceGenerator.data.fieldTaxNumber.info'
                      )}
                      placement="bottom"
                    >
                      <IconInfo />
                    </Tooltip>
                  </Flex>
                ),
                name: 'taxNumber',
              }}
              control={{
                placeholder: translate(
                  'contactsPage.newContact.fieldTaxNumber.placeholder'
                ),
              }}
            />

            <Col span={14}>
              <Form.Item
                label={translate('contactsPage.newContact.fieldVatType.label')}
              >
                <DeafSelect
                  placeholder={translate(
                    'invoiceGenerator.data.fieldVatType.placeholder'
                  )}
                  size="large"
                  value={
                    selectedType &&
                    tDynamic(`common.vatType.${selectedType}.title`)
                  }
                  onClick={() => setIsSelectTypeVisible(true)}
                />
              </Form.Item>
            </Col>
          </Row>
        </S.Card>

        <S.Card>
          <Row gutter={[12, 0]}>
            <ContactsWrapperFormControl
              type="input"
              span={10}
              form={{
                label: translate('invoiceGenerator.data.fieldStreet.label'),
                name: ['billingAddress', 'addressLine'],
              }}
            />

            <ContactsWrapperFormControl
              type="input"
              span={7}
              form={{
                label: translate('invoiceGenerator.data.fieldNumber.label'),
                name: ['billingAddress', 'houseNumber'],
              }}
            />

            <ContactsWrapperFormControl
              type="input"
              span={7}
              form={{
                label: translate('invoiceGenerator.data.fieldPostalCode.label'),
                name: ['billingAddress', 'postalCode'],
              }}
            />

            <ContactsWrapperFormControl
              type="select"
              span={10}
              form={{
                label: translate('invoiceGenerator.data.fieldCountry.label'),
                name: ['billingAddress', 'countryCode'],
              }}
              control={{
                options: countries,
              }}
            />

            <ContactsWrapperFormControl
              type="input"
              span={14}
              form={{
                label: translate('invoiceGenerator.data.fieldCity.label'),
                name: ['billingAddress', 'city'],
              }}
            />
          </Row>
        </S.Card>

        <S.Card>
          <ContactsWrapperFormControl
            type="input"
            form={{
              label: translate(
                'contactsPage.newContact.fieldContactPerson.label'
              ),
              name: 'contactPerson',
            }}
          />

          <Row gutter={[12, 0]}>
            <ContactsWrapperFormControl
              type="input"
              span={12}
              form={{
                label: translate('contactsPage.newContact.fieldEmail.label'),
                name: 'email',
              }}
            />

            <ContactsWrapperFormControl
              type="input"
              span={12}
              form={{
                label: translate('contactsPage.newContact.fieldPhone.label'),
                name: 'phone',
              }}
            />
          </Row>
        </S.Card>

        <S.Submit>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={onCancel}>
                {translate('contactsPage.newContact.buttonCancel')}
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                loading={loadingCreate || loadingUpdate}
              >
                {isTypeCreate
                  ? translate('contactsPage.newContact.buttonAdd')
                  : translate('contactsPage.editContact.buttonSave')}
              </Button>
            </Flex>
          </Form.Item>
        </S.Submit>
      </Form>
    </Modal>
  );
};

export default ContactAction;
