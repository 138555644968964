import { message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { Schemas } from '@api-client/generated/types';
import { IconDownload, IconZoomIn, IconZoomOut } from '@assets';
import { Loader } from '@components';
import { useElectronAPI, usePDFScaling, useTranslate } from '@hooks';

import * as S from './styled';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

type FileViewerProps = {
  selectedFile?:
    | Schemas.CompanyFile
    | Schemas.Document
    | Schemas.TaskFile
    | Schemas.TaxFile;
  loading?: boolean;
  width?: string;
  height?: string;
};

const isNotTaskFile = (
  file: FileViewerProps['selectedFile']
): file is Schemas.CompanyFile | Schemas.Document | Schemas.TaxFile =>
  (file && Object.prototype.hasOwnProperty.call(file, 'name')) || false;

const FileViewer: FC<FileViewerProps> = ({ selectedFile, width, height }) => {
  const { translate } = useTranslate();
  const [showLoader, setShowLoader] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [file, setFile] = useState<string | File | null>();

  const initialWidth = 405;
  const stepSize = 0.3;
  const magnifierMax = 4;

  const { scale, scaleUp, scaleDown, scaleReset } = usePDFScaling(
    initialWidth,
    stepSize,
    magnifierMax
  );

  useElectronAPI({ scaleUp, scaleDown, scaleReset });

  useEffect(
    () => {
      setShowLoader(true);
      if (selectedFile) {
        fetch(selectedFile.url)
          .then((response) => {
            setShowLoader(false);
            return response.blob();
          })
          .then((blob) => {
            setFile(URL.createObjectURL(blob));
            scaleReset();
            setShowLoader(false);
          })
          .catch(() => {
            message.error(translate('common.fileViewer.errorFetching'));
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFile?.id]
  );

  const handleDownload = () => {
    if (file instanceof Blob) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(file);
      link.download = isNotTaskFile(selectedFile)
        ? selectedFile.name
        : 'document.pdf';
      link.click();
      URL.revokeObjectURL(link.href);
    } else if (typeof file === 'string') {
      const link = document.createElement('a');
      link.href = file;
      link.download = isNotTaskFile(selectedFile)
        ? selectedFile.name
        : 'document.pdf';
      link.click();
    }
  };

  return (
    <S.Viewer>
      <S.Wrapper width={width} height={height}>
        <S.DocumentContainer>
          {showLoader ? (
            <Loader />
          ) : (
            <Document
              file={file}
              onLoadSuccess={({ numPages }) => {
                setPageCount(numPages);
              }}
              loading={<Loader />}
              error={translate('common.fileViewer.failedToLoad')}
              noData=""
            >
              {Array.from({ length: pageCount }, (_, index) => (
                <S.PageContainer>
                  <Page
                    key={`page_${scale}`}
                    pageNumber={index + 1}
                    scale={scale}
                    loading=""
                    width={initialWidth}
                  />
                </S.PageContainer>
              ))}
            </Document>
          )}
        </S.DocumentContainer>
      </S.Wrapper>

      {!showLoader && file && (
        <S.Panel align="center" className="preview_panel">
          <S.PanelButton type="text" icon={<IconZoomIn />} onClick={scaleUp}>
            {translate('common.fileViewer.zoomIn')}
          </S.PanelButton>
          <S.PanelButton type="text" icon={<IconZoomOut />} onClick={scaleDown}>
            {translate('common.fileViewer.zoomOut')}
          </S.PanelButton>
          <S.PanelButton
            type="text"
            icon={<IconDownload />}
            onClick={handleDownload}
          >
            {translate('common.fileViewer.download')}
          </S.PanelButton>
        </S.Panel>
      )}
    </S.Viewer>
  );
};

export default FileViewer;
