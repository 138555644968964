import { Button, Flex, Modal, Upload } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { ImportAttention } from '@assets';
import { Loader } from '@components';
import { useAccount, useTranslate } from '@hooks';
import { useCreateFileMappings } from '@hooks-api';

import * as S from './styled';

type ImportConfirmModalProps = {
  accountId: string;
  open: boolean;
  setOpen: (value: boolean) => void;
};

const ImportConfirmModal: FC<ImportConfirmModalProps> = ({
  open,
  setOpen,
  accountId,
}) => {
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const { companyId } = useAccount();

  const { mutate: createFileMappings, isLoading: createFileLoading } =
    useCreateFileMappings();

  const handleCloseModal = () => setOpen(false);

  const handleFileAttach = (options: UploadRequestOption) => {
    createFileMappings(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          accountId: accountId!,
          mapping: {
            topRowsSkipAmount: 0,
            bottomRowsSkipAmount: 0,
          } as Schemas.CSVMapping,
          file: options.file as string,
        },
      },
      {
        onSuccess: () => {
          navigate(`/transactions-import/${accountId}`);
        },
      }
    );
  };

  return (
    <Modal
      width={760}
      closeIcon={null}
      open={open}
      onCancel={handleCloseModal}
      footer={
        <S.Footer justify="flex-end" align="center" gap={12}>
          <Upload customRequest={handleFileAttach} showUploadList={false}>
            {createFileLoading ? (
              <S.LoaderWrapper justify="center" align="center">
                <Loader />
              </S.LoaderWrapper>
            ) : (
              <Button type="default" loading={createFileLoading}>
                <S.Action>
                  {translate('accounts.import.buttons.confirm')}
                </S.Action>
              </Button>
            )}
          </Upload>
          <Button type="primary" onClick={handleCloseModal}>
            {translate('accounts.import.buttons.decline')}
          </Button>
        </S.Footer>
      }
      destroyOnClose
      centered
    >
      <Flex vertical align="center" justify="center">
        <S.ImageWrapper>
          <S.Image src={ImportAttention} alt="attention" />
        </S.ImageWrapper>
        <S.TextContainer gap={14} align="center" justify="center" vertical>
          <S.Title>{translate('accounts.import.confirmationText')}</S.Title>
          <div>
            <span>
              {translate('accounts.import.confirmationDescriptionFirst')}
            </span>
            <S.Bold>
              {translate('accounts.import.confirmationDescriptionSecond')}
            </S.Bold>
            <span>
              {translate('accounts.import.confirmationDescriptionThird')}
            </span>
          </div>
          <div>
            <span>{translate('accounts.import.contactUsFirst')}</span>
            <S.Primary>
              <S.Bold>{translate('accounts.import.contactUsSecond')}</S.Bold>
            </S.Primary>
          </div>
        </S.TextContainer>
      </Flex>
    </Modal>
  );
};

export default ImportConfirmModal;
