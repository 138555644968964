import { Flex } from 'antd';

import { IconPlus, IconTrash } from '@assets';
import { useTranslate } from '@hooks';
import { Button } from '@ui-kit/Button/Button';

import RulesFormControlsList from '../RulesFormControlsList';
import { Control, FormValues } from '../utils';
import * as S from './styled';

type RulesFormConstructorProps<
  TOptionValue extends string,
  TSelectorFieldName extends string,
  TOperatorFieldName extends string,
> = {
  title: string;
  controls: Control<TOptionValue, TSelectorFieldName, TOperatorFieldName>;
  onChange: (
    item: FormValues<TOptionValue, TSelectorFieldName, TOperatorFieldName>[]
  ) => void;
  addRowButtonTitle: string;
  additionalButton?: {
    title: string;
    onClick: VoidFunction;
  };
  formValues: FormValues<
    TOptionValue,
    TSelectorFieldName,
    TOperatorFieldName
  >[];
};

const RulesFormConstructor = <
  TOptionValue extends string,
  TSelectorFieldName extends string,
  TOperatorFieldName extends string,
>({
  title,
  controls,
  onChange,
  addRowButtonTitle,
  formValues,
  additionalButton,
}: RulesFormConstructorProps<
  TOptionValue,
  TSelectorFieldName,
  TOperatorFieldName
>) => {
  const { translate } = useTranslate();

  const canAddMoreRows = controls.selector.uniqueValue
    ? formValues.length < controls.selector.props.options.length
    : true;

  const disabledSelectorValues: any[] = controls.selector.uniqueValue
    ? formValues
        .map<TOptionValue | undefined>(
          (
            item: FormValues<
              TOptionValue,
              TSelectorFieldName,
              TOperatorFieldName
            >
          ) => {
            const selectorValue: TOptionValue | undefined =
              item[controls.selector.fieldName];

            return selectorValue;
          }
        )
        .filter(Boolean)
    : [];

  const createOnChange = (index: number) => (field: string, value: string) =>
    onChange(
      formValues.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );

  const createOnRemoveRow = (index: number) => () =>
    onChange(formValues.filter((_, i) => i !== index));

  const onAddRow = () => onChange([...formValues, {}]);

  return (
    <Flex gap={16} vertical>
      <S.Card size="small" title={title}>
        {formValues.map((_, index) => (
          <Flex
            align="flex-start"
            justify="space-between"
            gap={20}
            vertical
            key={index}
          >
            <S.Field withSeparation={index < formValues.length - 1}>
              <Flex gap={12} align="center" justify="space-between">
                <RulesFormControlsList
                  controls={controls}
                  values={formValues[index]}
                  disabledSelectorValues={disabledSelectorValues}
                  onChange={createOnChange(index)}
                />

                <S.RemoveBox>
                  {formValues.length > 1 && (
                    <IconTrash onClick={createOnRemoveRow(index)} />
                  )}
                </S.RemoveBox>
              </Flex>

              {index < formValues.length - 1 && (
                <S.Separator justify="center" align="center">
                  {translate('rules.modal.and')}
                </S.Separator>
              )}
            </S.Field>

            {canAddMoreRows && index === formValues.length - 1 && (
              <Flex gap={24}>
                <Button
                  type="text"
                  size="xs"
                  icon={<IconPlus />}
                  onClick={onAddRow}
                >
                  {addRowButtonTitle}
                </Button>

                {additionalButton && (
                  <Button
                    type="text"
                    size="xs"
                    onClick={additionalButton.onClick}
                  >
                    {additionalButton.title}
                  </Button>
                )}
              </Flex>
            )}
          </Flex>
        ))}
      </S.Card>
    </Flex>
  );
};

export default RulesFormConstructor;
