import { Button, Flex, Typography } from 'antd';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { MessageProcessPaymentToRcs } from '@assets';
import { OnboardingMessage } from '@entities';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useUpdateIncorporation } from '@hooks-api';

import * as S from './styled';

const { Paragraph, Text } = Typography;

const OnboardingMessageSarlSRcs: FC = () => {
  const { translate } = useTranslate();
  const { updateScreen, updateStep, updateIncorporation } = useOnboarding();
  const { companyId } = useAccount();

  const { mutate: update, isLoading: loading } = useUpdateIncorporation();

  const handleContinue = () => {
    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          group: 'rcs',
        },
      },
      {
        onSuccess: () => {
          updateIncorporation({ group: 'rcs' });

          updateStep('BANK_TRANSFERRING', { status: 'completed' });

          updateStep('REGISTRATION_IN_RCS', {
            active: true,
            status: 'inProgress',
          });

          updateScreen('INCORPORATION', { active: true });
        },
      }
    );
  };

  return (
    <S.Container>
      <OnboardingMessage
        picture={<MessageProcessPaymentToRcs />}
        title={translate('onboarding.messages.sarlsRcs.title')}
        description={
          <Trans
            i18nKey={translate('onboarding.messages.sarlsRcs.description')}
            components={[<Paragraph />, <Text strong />]}
          />
        }
      />

      <Flex justify="flex-end">
        <Button type="primary" onClick={handleContinue} loading={loading}>
          {translate('onboarding.buttonContinue')}
        </Button>
      </Flex>
    </S.Container>
  );
};

export default OnboardingMessageSarlSRcs;
