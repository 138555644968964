import { Layout as BaseLayout } from 'antd';
import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { useUserController_getCurrentUser } from '@api-client/generated/UserController/getCurrentUser';
import { Loader } from '@components';
import { OnboardingProvider } from '@context';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAccount, useOnboarding } from '@hooks';
import { layout } from '@theme';
import { mediaWidth } from '@ui-kit/theme/mediaWidth';

import { Header } from './Header/Header';
import { Sidebar } from './Sidebar/Sidebar';

const Container = styled(BaseLayout, {
  shouldForwardProp: (prop) => prop !== 'withPadding',
})<{
  withPadding?: boolean;
}>`
  min-height: calc(100vh - 72px);
  margin-left: ${({ withPadding }) =>
    withPadding ? `${layout.sidebarWidth}px` : 0};
  margin-top: 72px;
  background-color: ${({ theme }) => theme.colors.bgLightBlue200};
`;

const Content = styled(BaseLayout.Content)`
  padding: 50px;

  ${mediaWidth.laptopM(css`
    padding: 0;
  `)};
`;

const Layout: FC = () => {
  const { steps, isLoading } = useOnboarding();
  const { isLogged, account, setAccount, company, userAccess, setUserAccess } =
    useAccount();
  const { data: user } = useUserController_getCurrentUser();
  const navigate = useNavigate();

  const { boot } = useIntercom();

  const { pathname } = useLocation();

  useEffect(() => {
    if (user && !account) {
      setAccount(user);
    }

    if (user) {
      setUserAccess(user.companies[0]?.userAccessRights);
    }

    if (account) {
      if (account.intercomHash) {
        boot({
          email: account.email,
          name: account.fullName,
          userId: account.id,
          userHash: account.intercomHash,
        });
      }
    }
  }, [user, account, setAccount, setUserAccess, boot]);

  useEffect(() => {
    if (isLogged) {
      if (company?.status === 'in_service') {
        navigate(userAccess?.transactions ? '/transactions' : '/documents');
      } else {
        if (pathname !== '/start') {
          navigate('/start');
        }
      }
    }
  }, [company?.status, isLogged, navigate, pathname, userAccess?.transactions]);

  const hasSidebar = !isLoading && !steps.COMPANY_NAME.active;

  return (
    <BaseLayout>
      <Header />

      <Container withPadding={hasSidebar}>
        {hasSidebar && <Sidebar />}

        <Content>{isLoading ? <Loader /> : <Outlet />}</Content>
      </Container>
    </BaseLayout>
  );
};

export const LayoutOnboarding = () => (
  <OnboardingProvider>
    <Layout />
  </OnboardingProvider>
);
