import { Flex, Form, Select, Typography, message } from 'antd';
import { Onfido } from 'onfido-sdk-ui';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import { CopyButton, Loader } from '@components';
import { useAccount, useOnboarding, useTranslate } from '@hooks';
import { useGetOnfidoData, useUpdateIncorporation } from '@hooks-api';

import * as S from './styled';

type KYCBiometricProps = {
  person: Schemas.Person;
};

const { Paragraph, Title } = Typography;

const onfidoContainerId = 'onfido-sdk-wrapper';

const KYCBiometric: FC<PropsWithChildren<KYCBiometricProps>> = ({ person }) => {
  const { translate, tDynamic } = useTranslate();
  const { companyId } = useAccount();

  const { incorporationDetails, updateIncorporation } = useOnboarding();

  const { mutate: update } = useUpdateIncorporation();
  const { mutate: getOnfidoData, isLoading: loading } = useGetOnfidoData();

  const [onfidoData, setOnfidoData] = useState<Schemas.OnfidoData | null>(null);

  const [form] = Form.useForm();

  const hasNationality = !!person.kycData?.nationality;

  useEffect(() => {
    if (!hasNationality) {
      return;
    }

    getOnfidoData(
      {
        requestBody: {
          personId: person.id,
          personName: person.name,
          nationality: person.kycData?.nationality,
          companyId: companyId!,
        },
      },
      {
        onSuccess: (data) => {
          setOnfidoData(data);

          Onfido.init({
            token: data.token,
            containerId: onfidoContainerId,
            onComplete: () => {
              const currentKycData = person.kycData || {};
              person.kycData = {
                ...currentKycData,
                hasCompletedBiometricCheck: true,
                hasFailedBiometricCheck: false,
              };

              update({
                parameter: {
                  companyId: companyId!,
                },
                requestBody: {
                  people: incorporationDetails!.people,
                },
              });

              updateIncorporation({ people: incorporationDetails!.people });
            },
            workflowRunId: data.workflowRunId,
          });
        },
      }
    );
  }, [
    companyId,
    getOnfidoData,
    incorporationDetails,
    person,
    update,
    updateIncorporation,
    hasNationality,
  ]);

  const sentLink = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      hasSentBiometricCheckLink: true,
      hasFailedBiometricCheck: false,
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  const onFinish = () => {
    const currentKycData = person.kycData || {};
    person.kycData = {
      ...currentKycData,
      ...form.getFieldsValue(),
    };

    update(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporationDetails!.people,
        },
      },
      {
        onSuccess: () => {
          message.success(translate('onboarding.saved'));
        },
      }
    );

    updateIncorporation({ people: incorporationDetails!.people });
  };

  return (
    <Flex gap={20} vertical>
      <div>
        <Title level={5}>{translate('onboarding.kyc.natural.id')}</Title>

        <Paragraph type="secondary">
          {translate('onboarding.kyc.naturalDetails.id')}
        </Paragraph>
      </div>

      <Flex vertical>
        {hasNationality && (
          <Form
            form={form}
            layout="vertical"
            colon={false}
            initialValues={person.kycData}
          >
            <Form.Item
              name="isFilingPerson"
              label={tDynamic(
                'onboarding.kyc.naturalKycForm.filingPerson.label',
                { value: person.name }
              )}
            >
              <Select
                placeholder={translate(
                  'onboarding.kyc.naturalKycForm.filingPerson.placeholder'
                )}
                options={[
                  {
                    label: translate(
                      'onboarding.kyc.naturalKycForm.filingPerson.values.yes'
                    ),
                    value: true,
                  },
                  {
                    label: translate(
                      'onboarding.kyc.naturalKycForm.filingPerson.values.no'
                    ),
                    value: false,
                  },
                ]}
                size="large"
                onChange={onFinish}
                virtual
                disabled={person?.kycData?.hasCompletedBiometricCheck}
              />
            </Form.Item>
          </Form>
        )}

        {!hasNationality && (
          <S.ErrorAlert
            type="error"
            message={t('onboarding.kyc.naturalKycForm.biometry.nationality')()}
          />
        )}

        {person?.kycData?.hasFailedBiometricCheck && (
          <S.ErrorAlert
            type="error"
            message={
              person?.kycData?.isFilingPerson === false ? (
                <Trans
                  i18nKey={translate(
                    'onboarding.kyc.naturalKycForm.biometry.errorOther'
                  )}
                  components={[<strong />]}
                  tOptions={{
                    name: person.name,
                  }}
                />
              ) : (
                translate('onboarding.kyc.naturalKycForm.biometry.errorSelf')
              )
            }
          />
        )}

        {person?.kycData?.isFilingPerson === false && (
          <S.ShareLink>
            <S.Instruction>
              <Trans
                i18nKey={translate(
                  'onboarding.kyc.naturalKycForm.biometry.share'
                )}
                components={[<strong />]}
                tOptions={{
                  name: person.name,
                }}
              />
            </S.Instruction>

            {loading ? (
              <Loader />
            ) : (
              <S.DisabledLikeEnabledInput
                value={onfidoData?.workflowRunLink}
                suffix={<CopyButton value={onfidoData?.workflowRunLink} />}
                size="large"
                disabled
              />
            )}

            <S.Notice>
              {translate('onboarding.kyc.naturalKycForm.biometry.notice')}
            </S.Notice>

            <S.ButtonNotified type="primary" onClick={sentLink}>
              {translate('onboarding.kyc.naturalKycForm.biometry.confirmation')}
            </S.ButtonNotified>
          </S.ShareLink>
        )}

        <div
          style={{
            display:
              person?.kycData?.isFilingPerson === true ? 'block' : 'none',
          }}
          id={onfidoContainerId}
        ></div>
      </Flex>
    </Flex>
  );
};

export default KYCBiometric;
