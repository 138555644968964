import { ElementType, FC, MouseEvent } from 'react';

import { formatNumber } from '@utils';

import * as S from './styled';

type AmountProps = {
  as?: ElementType;
  currencyCode?: string;
  amount: number | string;
  withoutType?: boolean;
  onClick?: (e: MouseEvent) => void;
};

const Amount: FC<AmountProps> = ({
  as: Component = S.Amount,
  withoutType,
  currencyCode,
  amount,
  onClick,
}) => {
  if (typeof amount === 'string') {
    return amount;
  }

  return (
    <Component
      type={
        !withoutType && amount !== 0
          ? amount > 0
            ? 'success'
            : 'danger'
          : undefined
      }
      onClick={onClick}
    >
      {currencyCode
        ? formatNumber(amount, { style: 'currency', currency: currencyCode })
        : formatNumber(
            amount,
            {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
            'fr-FR'
          )}
    </Component>
  );
};

export default Amount;
