import { Col, DatePicker, Form, Input, InputNumber, Row, message } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Schemas } from '@api-client/generated/types';
import { Select } from '@components';
import { currencyCodes } from '@constants';
import {
  FilterContacts,
  PaymentDocumentAttachedTransactions,
  PaymentDocumentSwitchControl,
  PaymentDocumentType,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useUpdateDocumentById } from '@hooks-api';
import { DATE_ISO_FORMAT, getDateDefault } from '@utils';

import * as S from './styled';

type PaymentDocumentFile = Schemas.Document;

type PaymentDocumentDetailsFormProps = {
  type: PaymentDocumentType;
  details: PaymentDocumentFile;
  onUpdate?: (file: PaymentDocumentFile | null) => void;
  onAttachTransaction?: (file: PaymentDocumentFile | null) => void;
  onRefreshFile: () => void;
  hasSwitches?: boolean;
};

const PaymentDocumentDetailsForm: FC<PaymentDocumentDetailsFormProps> = ({
  type,
  details,
  onUpdate,
  onAttachTransaction,
  onRefreshFile,
  hasSwitches = true,
}) => {
  const { translate } = useTranslate();
  const { companyId, userAccess } = useAccount();

  const translationPrefix = type === 'income' ? 'income' : 'expenses';

  const [selectedStatus, setSelectedStatus] = useState<string>('');

  const [form] = Form.useForm();

  const { mutate: updateFile } = useUpdateDocumentById();

  const isReviewed = details.documentMetadata.isReviewed;

  useEffect(() => {
    if (details) {
      const { contact, documentMetadata: data } = details;

      form.setFieldsValue({
        number: data.number,
        contact: contact?.id,
        description: data.description,
        issueDate: data.issueDate ? dayjs(data.issueDate) : null,
        dueDate: data.dueDate ? dayjs(data.dueDate) : null,
        amount: data.amount,
        currency: data.currency,
      });

      setSelectedStatus(details?.documentMetadata.status || '');
    }
  }, [details, form]);

  const handleUpdateDetails = useDebouncedCallback(() => {
    if (details.documentMetadata?.isReviewed) {
      form.submit();
    }
  }, 1000);

  const handleSubmit = (
    values: PaymentDocumentFile['documentMetadata'] & { contact: string }
  ) => {
    const formData = new FormData();

    if (selectedStatus) {
      formData.append('documentMetadata[status]', selectedStatus);
    }

    if (values.number) {
      formData.append('documentMetadata[number]', values.number!);
    }

    if (values.contact) {
      formData.append('contactId', values.contact!);
    }

    if (values.description) {
      formData.append('documentMetadata[description]', values.description!);
    }

    if (values.amount) {
      formData.append('documentMetadata[amount]', String(values.amount));
    }

    if (values.currency) {
      formData.append('documentMetadata[currency]', values.currency!);
    }

    if (values.issueDate) {
      formData.append(
        'documentMetadata[issueDate]',
        getDateDefault(values.issueDate!, DATE_ISO_FORMAT)
      );
    }

    if (values.dueDate) {
      formData.append(
        'documentMetadata[dueDate]',
        getDateDefault(values.dueDate!, DATE_ISO_FORMAT)
      );
    }

    if (details.documentMetadata?.ignoreDuplicate) {
      formData.append(
        'documentMetadata[ignoreDuplicate]',
        JSON.stringify(true)
      );
    }

    formData.append('documentMetadata[isReviewed]', JSON.stringify(true));

    updateFile(
      {
        parameter: {
          companyId: companyId!,
          id: details.id,
        },
        requestBody: formData as any,
      },
      {
        onSuccess: (response) => {
          onUpdate?.(response);

          message.success(
            translate(`${translationPrefix}.successfullyUpdated`)
          );
        },
      }
    );
  };

  const handleSelectStatus = (status: string) => {
    setSelectedStatus(status);

    if (details.documentMetadata?.isReviewed) {
      handleUpdateDetails();
    }
  };

  return (
    <S.Container>
      {hasSwitches && (
        <PaymentDocumentSwitchControl
          type={type}
          status={selectedStatus}
          onChange={handleSelectStatus}
        />
      )}

      <Form
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
        colon={false}
        layout="vertical"
      >
        <Form.Item
          label={translate(`${translationPrefix}.details.number.label`)}
          name="number"
        >
          <Input
            placeholder={translate(
              `${translationPrefix}.details.number.placeholder`
            )}
            size="large"
            onChange={handleUpdateDetails}
          />
        </Form.Item>

        <Form.Item
          label={translate(`${translationPrefix}.details.contact.label`)}
          name="contact"
        >
          <FilterContacts
            placeholder={translate(
              `${translationPrefix}.details.contact.placeholder`
            )}
            onChange={handleUpdateDetails}
          />
        </Form.Item>

        <Form.Item
          label={translate(`${translationPrefix}.details.description.label`)}
          name="description"
        >
          <Input.TextArea
            placeholder={translate(
              `${translationPrefix}.details.description.placeholder`
            )}
            size="large"
            onChange={handleUpdateDetails}
          />
        </Form.Item>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item
              label={translate(`${translationPrefix}.details.issueDate.label`)}
              name="issueDate"
            >
              <DatePicker
                placeholder={translate(
                  `${translationPrefix}.details.issueDate.placeholder`
                )}
                size="large"
                onChange={handleUpdateDetails}
                format="DD.MM.YYYY"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={translate(`${translationPrefix}.details.dueDate.label`)}
              name="dueDate"
            >
              <DatePicker
                placeholder={translate(
                  `${translationPrefix}.details.dueDate.placeholder`
                )}
                size="large"
                onChange={handleUpdateDetails}
                format="DD.MM.YYYY"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item
              label={translate(`${translationPrefix}.details.amount.label`)}
              name="amount"
            >
              <InputNumber
                placeholder={translate(
                  `${translationPrefix}.details.amount.placeholder`
                )}
                size="large"
                onChange={handleUpdateDetails}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={translate(`${translationPrefix}.details.currency.label`)}
              name="currency"
            >
              <Select
                options={currencyCodes.map((code) => ({
                  label: code,
                  value: code,
                }))}
                onChange={handleUpdateDetails}
                size="large"
                showSearch
              />
            </Form.Item>
          </Col>
        </Row>

        {!isReviewed && (
          <Form.Item noStyle>
            <S.ButtonSubmit type="primary" htmlType="submit" block>
              {translate(`${translationPrefix}.details.buttonSubmit`)}
            </S.ButtonSubmit>
          </Form.Item>
        )}

        {userAccess?.transactions && (
          <PaymentDocumentAttachedTransactions
            type={type}
            id={details.id}
            transactions={details?.transactions}
            potentialTransactions={details?.potentialTransactions}
            onAttachTransaction={onAttachTransaction}
            onRefreshFile={onRefreshFile}
          />
        )}
      </Form>
    </S.Container>
  );
};

export default PaymentDocumentDetailsForm;
