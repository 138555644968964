import { Button, Flex, message } from 'antd';
import { FC, useEffect, useState } from 'react';

import { useTransactionController_findAll } from '@api-client/generated/TransactionController/findAll';
import { Schemas } from '@api-client/generated/types';
import { BankDefaultLogotype } from '@assets';
import {
  AttachTemplateItem,
  PaymentDocumentTransaction,
  PaymentDocumentType,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useUpdateDocumentById } from '@hooks-api';

import * as S from './styled';

type Transaction = Schemas.Transaction;

type PaymentDocumentAttachedTransactionsProps = {
  type: PaymentDocumentType;
  id: string;
  transactions: Transaction[];
  potentialTransactions: Transaction[];
  onAttachTransaction?: (file: Schemas.Document | null) => void;
  onRefreshFile: () => void;
};

const PaymentDocumentAttachedTransactions: FC<
  PaymentDocumentAttachedTransactionsProps
> = ({
  type,
  id,
  transactions,
  potentialTransactions,
  onAttachTransaction,
  onRefreshFile,
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const translationPrefix = type === 'income' ? 'income' : 'expenses';

  const [attachedTransactions, setAttachedTransactions] = useState<
    Transaction[]
  >([...transactions, ...(potentialTransactions || [])]);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [documentsList, setDocumentsList] = useState<any[]>([]);
  const [selectedTransactions, setSelectedTransactions] = useState<
    string[] | []
  >([]);

  const { loading: transactionsLoading } = useTransactionController_findAll({
    params: {
      companyId: companyId!,
      term: searchValue,
      ...(type === 'income' ? { amountFrom: 0 } : { amountTo: 0 }),
    },
    config: {
      enabled: attachedTransactions?.some(({ id }) => id === 'template'),
      onSuccess: (res) => {
        setDocumentsList(res.records);
      },
    },
  });

  const isAttachedTransactions = attachedTransactions?.length;
  const isPotentialTransactions = potentialTransactions?.length;

  const { mutate: updateFile } = useUpdateDocumentById();

  const isTransactions = transactions.length;

  useEffect(() => {
    if (transactions) {
      setAttachedTransactions([...transactions]);
    }
  }, [transactions]);

  const fileUpdate = (formData: unknown, isClearTransactions?: boolean) =>
    updateFile(
      {
        parameter: {
          companyId: companyId!,
          id,
        },
        requestBody: formData as { transactionIds: string },
      },
      {
        onSuccess: (response) => {
          message.success(
            translate(`${translationPrefix}.successfullyUpdated`)
          );
          onAttachTransaction?.(response);
          isClearTransactions && setSelectedTransactions([]);
        },
      }
    );

  const handleSubmit = (transactions: Transaction[]) => {
    const formData = new FormData();

    setAttachedTransactions(transactions);

    formData.append('transactionIds', selectedTransactions.join(','));

    fileUpdate(formData, true);
  };

  const handleCreateTransactionAttachTemplate = () => {
    setAttachedTransactions(
      (prev) => [{ id: 'template' }, ...prev] as Transaction[]
    );
  };

  const handleDeleteTemplate = () => {
    setAttachedTransactions((prev) =>
      prev.filter(({ id }) => id !== 'template')
    );
  };

  const getLogoFromTransaction = (transaction: Transaction) => {
    if (transaction?.account?.connection?.bank.bic) {
      return `./${transaction?.account?.connection?.bank.bic}.png`;
    }

    return BankDefaultLogotype;
  };

  const handleAttachTransaction = (transaction: Schemas.Transaction) => {
    setAttachedTransactions([
      ...(attachedTransactions?.filter(
        ({ id }) => id !== 'template'
      ) as Transaction[]),
      transaction,
    ]);

    const formData = new FormData();

    formData.append(
      'transactionIds',
      [
        ...(attachedTransactions?.filter(
          ({ id }) => id !== 'template'
        ) as Transaction[]),
        transaction,
      ]
        ?.map((transaction) => transaction.id)
        .join(',')
    );

    fileUpdate(formData);
  };

  const handleDetachTransaction = (transactionId: string) => {
    const newData = attachedTransactions
      .filter((transaction) => transaction.id !== transactionId)
      .filter(({ id }) => id !== 'template');

    const formData = new FormData();

    formData.append(
      'transactionIds',
      newData?.map((transaction) => transaction.id).join(',')
    );

    fileUpdate(formData);
  };

  useEffect(() => {
    if (potentialTransactions?.length === 1) {
      setSelectedTransactions([potentialTransactions[0].id]);
    }
  }, [potentialTransactions]);

  const handleSelectTransactions = (transactionId: string) => {
    setSelectedTransactions((prev) =>
      prev.some((id) => transactionId === id)
        ? prev.filter((id) => transactionId !== id)
        : [...prev, transactionId]
    );
  };

  const renderAttachedTransactions = () =>
    attachedTransactions.map((transaction) =>
      transaction.id !== 'template' ? (
        <PaymentDocumentTransaction
          type={type}
          logo={getLogoFromTransaction(transaction)}
          key={transaction.id}
          transaction={transaction}
          onRefreshFile={onRefreshFile}
          onDetach={() => handleDetachTransaction(transaction.id)}
          selected={false}
        />
      ) : (
        <AttachTemplateItem
          documentsList={documentsList}
          isListLoading={transactionsLoading}
          onAttach={handleAttachTransaction}
          onSearch={setSearchValue}
          onDelete={handleDeleteTemplate}
        />
      )
    );

  const renderPotentialTransactions = () =>
    potentialTransactions.map((transaction) => (
      <PaymentDocumentTransaction
        type={type}
        logo={getLogoFromTransaction(transaction)}
        key={transaction.id}
        transaction={transaction}
        onRefreshFile={onRefreshFile}
        selected={selectedTransactions?.some((id) => id === transaction.id)}
        onSelect={() => handleSelectTransactions(transaction.id)}
      />
    ));

  const renderTemplate = () =>
    isPotentialTransactions &&
    attachedTransactions
      .filter((transaction) => transaction.id === 'template')
      .map(() => (
        <AttachTemplateItem
          documentsList={documentsList}
          isListLoading={transactionsLoading}
          onAttach={handleAttachTransaction}
          onSearch={setSearchValue}
          onDelete={handleDeleteTemplate}
        />
      ));

  return (
    <S.Container>
      <Flex align="center" justify="space-between">
        {isTransactions ||
        isAttachedTransactions ||
        !attachedTransactions?.some(({ id }) => id !== 'template') ? (
          <S.Title>
            {translate(`${translationPrefix}.attachedTransactions.title`)}
          </S.Title>
        ) : (
          <S.Title>
            {translate(`${translationPrefix}.noAttachedTransactions.title`)}
          </S.Title>
        )}

        {!isPotentialTransactions &&
        !attachedTransactions?.some(({ id }) => id === 'template') ? (
          <S.ButtonLink onClick={handleCreateTransactionAttachTemplate}>
            {translate(
              `${translationPrefix}.noAttachedTransactions.buttonAttach`
            )}
          </S.ButtonLink>
        ) : null}
      </Flex>

      {!isTransactions &&
        !isAttachedTransactions &&
        !isPotentialTransactions && (
          <S.Description>
            {translate(
              `${translationPrefix}.noAttachedTransactions.description`
            )}
          </S.Description>
        )}

      {isPotentialTransactions ? (
        <S.Description>
          {translate(`${translationPrefix}.attachedTransactions.description`)}
        </S.Description>
      ) : null}

      <S.Transactions gap={12} vertical>
        {renderTemplate()}

        {isPotentialTransactions
          ? renderPotentialTransactions()
          : renderAttachedTransactions()}
      </S.Transactions>

      {isPotentialTransactions && (
        <Flex align="center" gap={12}>
          <Button
            size="small"
            onClick={handleCreateTransactionAttachTemplate}
            block
          >
            {translate(`${translationPrefix}.details.buttonChoose`)}
          </Button>

          <Button
            type="primary"
            size="small"
            onClick={() => handleSubmit(attachedTransactions)}
            disabled={selectedTransactions?.length === 0}
            block
          >
            {translate(`${translationPrefix}.details.buttonYes`)}
          </Button>
        </Flex>
      )}
    </S.Container>
  );
};

export default PaymentDocumentAttachedTransactions;
