import { Flex, Switch } from 'antd';
import { useEffect } from 'react';

import { IconPlus } from '@assets';
import { Loader } from '@components';
import { useGetRules } from '@entities/rules/useGetRules';
import { useLinkedData } from '@entities/rules/useLinkedData';
import { useAccount } from '@hooks';
import { Button } from '@ui-kit/Button/Button';

import { EmptyRulesList } from './list/EmptyRulesList/EmptyRulesList';
import { Rule } from './list/Rule';
import { useRulesList } from './list/useRulesList';
import * as S from './styled';

const tabs = ['transaction', 'file'] as const;

export const Rules = () => {
  const { companyId } = useAccount();

  if (!companyId) {
    throw new Error('Company ID is not defined');
  }

  const { categories, contacts, projects } = useLinkedData();

  const {
    handleArchiveRule,
    handleRestoreRule,
    isLoading,
    rules,
    refetch,
    toggleArchivedRules,
  } = useRulesList({
    companyId,
  });

  const { setConfig, setStep } = useGetRules();

  useEffect(() => {
    setConfig({
      onSuccess: refetch,
      tabs,
    });
  }, [refetch, setConfig]);

  return (
    <Flex gap={32} vertical>
      <Flex justify="space-between">
        <S.Title>{t('rules.title')()}</S.Title>
      </Flex>

      <Flex justify="space-between">
        <Flex gap={12}>
          <Switch onChange={toggleArchivedRules} />
          <S.OnlyArchived>{t('rules.showOnlyArchived')()}</S.OnlyArchived>
        </Flex>
        <Button icon={<IconPlus />} onClick={() => setStep('form')}>
          {t('rules.addNewRule')()}
        </Button>
      </Flex>

      <S.Content vertical>
        {isLoading ? (
          <Loader />
        ) : rules?.length ? (
          <ul>
            {rules.map((item) => (
              <Rule
                key={item.id}
                item={item}
                restoreRule={handleRestoreRule}
                archiveRule={handleArchiveRule}
                projects={projects}
                categories={categories}
                contacts={contacts}
              />
            ))}
          </ul>
        ) : (
          <EmptyRulesList onAddRule={() => setStep('form')} />
        )}
      </S.Content>
    </Flex>
  );
};
